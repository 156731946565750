import { makeStyles} from '@material-ui/core';
import React from 'react';
import { SubmitButton } from '../../common/button/submit';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ShippingRatesList } from '../rates/shippingRatesList';
import { IRate } from '../store/types';

const useStyles = makeStyles({
    container: {
        position: 'fixed',
        top: 0,
        height: '100%',
        width: '100%',
        maxWidth: '560px',
        boxSizing: 'border-box',
        zIndex: 1001,
        transition: 'right 0.2s',
        backgroundColor: '#c7d0d4',
        overflowY: 'auto',
    },
    innerContainer: {
      padding: '48px 56px',
      ['@media (max-width:520px)']: { // eslint-disable-line no-useless-computed-key
        padding: '20px 0px'
      }
    },
    header: {
        fontSize: '18px',
        display: 'block',
        ['@media (max-width:520px)']: { // eslint-disable-line no-useless-computed-key
          paddingTop: '24px',
          paddingLeft: '16px',
        }
    },
    innerHeader: {
      paddingTop: '20px',
    },
    backButton: {
      position: 'fixed',
      top: '16px',
      left: '40px',
      zIndex: 1002,
    }
  });
  interface ShippingRatesPageRenderProps {
    className: string,
    rates: IRate[],
    onBackClick: () => void,
    onSubmit: () => void,
    onShippingRateSelected: (rateId: string | undefined) => void,
  }

const ShippingRatesPage: React.FC<ShippingRatesPageRenderProps> = ({className, onBackClick, onSubmit, rates, onShippingRateSelected}) => {
    const classes = useStyles();

    return <>  
        <ArrowBackIosIcon className={classes.backButton} onClick={() => onBackClick()}></ArrowBackIosIcon>
        <div className={`${className} ${classes.container}`}>
          <div className={`${classes.innerContainer}`}>
          <span className={classes.header}>Shipping Rate Selection</span>
        {rates && rates.length && <ShippingRatesList onSelectShippingRate={onShippingRateSelected} rates={rates}></ShippingRatesList>}
        </div>
        <SubmitButton onClick={() => onSubmit()} text='Checkout'></SubmitButton>
      </div>
    </>;
}

export default ShippingRatesPage;
