import React, { Component } from 'react';
import CategoryList from '../CategoryList';
import FloatCart from '../checkout/floatCart/FloatCart';
import logo from '../../assets/icons/main-logo.png';
import { connect } from 'react-redux';
import { handleWindowSizeChange } from '../../store/global/actions';
import PropTypes from 'prop-types';

import './style.scss';
import { BaseComponent } from '../../utils/core/baseComponent';
import { IRootState } from '../../store/state';

export interface IHeaderProps {
  isMobile?: boolean,
  bannerMessage?: string,
  handleWindowSizeChange: () => void,
};


class Header extends BaseComponent<IHeaderProps> {

  constructor(props: IHeaderProps) {
    super(props);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
  }


  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    
    this.props.handleWindowSizeChange();
    const localThis = this
    setTimeout((function handle() {
      localThis.props.handleWindowSizeChange();
    }).bind(this), 1000);
  }
  
  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange() {
    this.props.handleWindowSizeChange();
  }

  render() {

    let headerClasses = ['header-element'];
    if (this.props.bannerMessage) headerClasses.push('banner-enabled');

    let logoClasses = ['header-logo'];
    if (this.props.isMobile) {
      logoClasses.push('mobile');
    }

    return (
      <div className={headerClasses.join(' ')}>
        <div className='logo-container'>
          <a href="/">
            <img className={logoClasses.join(' ')} src={logo} alt="Logo" />
          </a>
        </div>
        <CategoryList />
        <FloatCart />
    </div>
    );
  }
}


const mapStateToProps = (state: IRootState) => ({
  isMobile: state.global.isMobile,
  bannerMessage: state.global.bannerMessage,
});

export default connect(
  mapStateToProps,
  { handleWindowSizeChange }
)(Header);

