import { Action } from "@reduxjs/toolkit";

export const ChangeSubscribeModal = 'CHANGE_SUBSCRIBE_MODEL';

export interface IChangeSubscribeModalAction extends Action<typeof ChangeSubscribeModal> {
    display?: boolean;
}

export function changeSubscribeModal(display?: boolean) {
    return {
        type: ChangeSubscribeModal,
        display,
    }
}

export type ISubscribeEmailActions = IChangeSubscribeModalAction;