import 'babel-polyfill'
import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';
import Root from './Root';
import './index.scss';
import './bootstrap.min.css';
import { initializeIcons } from 'office-ui-fabric-react';

initializeIcons();

function render() {
  return ReactDOM.render(
    <Root>
      <App />
    </Root>,
    document.getElementById('root')
  );
}

render();