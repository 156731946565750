export interface IBlogState {
    list: IBlogList;
    post?: IBlogPost;
}

export const initialBlogState: IBlogState = {
    list: []
}

export interface IBlogPost {
    authorImage: string;
    name: string;
    mainImage: string;
    body: any[];
    title: string;
}

export interface IBlogList extends Array<any> {

}
