import { makeStyles } from "@material-ui/core";
import React from 'react';

const useStyles = makeStyles({
    captionImage: {
        maxWidth: '150px',
        maxHeight: '150px',
      },
      imageSelectionCaption: {
        flexGrow: 1,
        minWidth: '92px',
        fontSize: '24px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      imageContainerSelected: {
        borderColor: 'black !important',
        borderWidth: '2px !important'
      },
      imageContainer: {
        '&:hover': {
          backgroundColor: '#c7d0d4'
        },
        '&::before': {
          content: '""',
          display: 'inline-block',
          verticalAlign: 'middle',
          height: '100%'
        },
        minWidth: '186px',
        cursor: 'pointer',
        height: 'inherit',
        width: '176px',
        display: 'block',
        verticalAlign: 'middle',
        border: '1px solid #ddd',
        borderRadius: '4px',
        margin: '16px auto',
        flexGrow: 0.2,
        ['@media (min-width:810px)']: { // eslint-disable-line no-useless-computed-key
          margin: '0 auto',
          flexBasis: '40%'
        },
        ['@media (min-width:480px)']: { // eslint-disable-line no-useless-computed-key
          margin: '0 32px',
          flexGrow: 1
        }
      },
      binaryImageSelectionRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
      },
      imagesContainer: {
        flexGrow: 1,
        display: 'flex',
        ['@media (max-width:480px)']: { // eslint-disable-line no-useless-computed-key
          display: 'block'
        }
      },
      colorPicker: {
        justifyContent: 'center',
        margin: 'auto',
      },
      colorPickerLabel: {
        fontWeight: 600,
        textAlign: 'center'
      }
});

export interface IImagePickerProps {
    selected: string;
    images: string[];
    caption: string;
    onChange: (selected: string) => void;
}

export function ImagePicker(props: IImagePickerProps) {
    const classes = useStyles();
    const firstImageClasses: string[] = [classes.imageContainer];
      const secondImageClasses: string[] = [classes.imageContainer];
      const images = props.images;

      if (props.selected === images[0]) {
        firstImageClasses.push(classes.imageContainerSelected);
      } else if (props.selected === images[1]) {
        secondImageClasses.push(classes.imageContainerSelected);
      }
      const imageClicked = (index: 0 | 1) => props.onChange(images[index]);

      return <div className={classes.binaryImageSelectionRoot}>  
          <span className={classes.imageSelectionCaption}>{props.caption}</span>
          <div className={classes.imagesContainer}>

            <div onClick={(_) => imageClicked(0)} className={firstImageClasses.join(' ')}>
              <img className={classes.captionImage} alt="logo1" src={images[0]} />
            </div>
            <div onClick={(_) => imageClicked(1)} className={secondImageClasses.join(' ')}>
            <img className={classes.captionImage} alt="logo2" src={images[1]} />
            </div>
          </div>
      </div>
}