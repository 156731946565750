import { FetchProduct, FetchProductFailure, FetchProductSuccess, ShelfActions } from './actionTypes';
import { IShelfState } from './state';

export const initialShelfState: IShelfState = {
  products: []
};

export function shelfReducer(state: IShelfState = initialShelfState, action: ShelfActions): IShelfState {
  switch (action.type) {
    case FetchProduct: 
      return {
        ...state,
        isLoading: true,
      }
    case FetchProductSuccess:
      let newProducts = action.payload;  
      if (action.payload.length === 1 && state.products && state.products.length > 0) {
        const index = state.products.findIndex(p => p.id === action.payload[0].id);
        
        newProducts = [
          ...state.products
        ];

        if (index > -1) {
          newProducts[index] = action.payload[0];
        } else {
          newProducts.push(action.payload[0]);
        }
      }


      return {
        ...state,
        products: newProducts,
        isLoading: false,
      };
    case FetchProductFailure:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
