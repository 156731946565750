import { IProduct, SizeValues } from "../types";

export function getProductQuantity(product: IProduct, size?: SizeValues): number {

  const sizeQuantity = (getAvailableSizeCount(product, size) || 0);
  const productQuantity = product.type === 'customsurfboard' ? 10 : product.quantity;
  const quantity = product.type === 'apparel' ? sizeQuantity : productQuantity;

  return quantity;
}

export function getAvailableSizeCount(product: IProduct, size?: SizeValues) {
    if (!size || (product.sizes && product.sizes.isUniversal)) return product.quantity;
    
    switch(size) {
      case 'XS':
        return product.sizes?.extraSmall.available;
      case 'S':
        return product.sizes?.small.available;
      case 'M':
        return product.sizes?.medium.available;
      case 'L':
        return product.sizes?.large.available;
      case 'XL':
        return product.sizes?.extraLarge.available;
      case 'XXL':
        return product.sizes?.xxLarge.available;
      case 'XXXL':
        return product.sizes?.xxxLarge.available;
      default: 
        return 0;
    }
  }