import WelcomeSlider from '../../WelcomeSlider';
import { Route } from 'react-router-dom';
import React from 'react';
import { Contact } from '../../Contact/contact';
import ProductDetails from '../../Shelf/Details/ProductDetails';
import About from '../../About/about';
import Shelf from '../../Shelf/Shelf';
import Create from '../../Create/create';
import Success from '../../Success/success';
import { Failure } from '../../Failure/failure';
import BlogList from '../../Blog/blogList';
import blogPost from '../../Blog/blogPost';

function AppRouter() {
    return (
        <React.Fragment>
            <Route exact path="/" component={WelcomeSlider} />
            <Route exact path="/Home" component={WelcomeSlider} />
            <Route exact path="/Surfboards" component={Shelf}/>
            <Route path="/Surfboards/:id" render={(props: any) => <ProductDetails {...props} />} />
            <Route exact path="/Apparel" component={Shelf} />
            <Route path="/Apparel/:id" render={(props: any) => <ProductDetails {...props} />} />
            <Route exact path="/Contact" component={Contact} />
            <Route exact path="/About" component={About} />
            <Route exact path="/Create" component={Create} />
            <Route exact path="/Blog" component={BlogList} />
            <Route exact path="/Blog/:slug" component={blogPost} />
            <Route exact path="/Failed/" component={Failure} />
            <Route exact path="/Success" component={Success} />
        </React.Fragment>

    );
}

export default AppRouter;