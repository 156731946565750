import { Action } from "redux";
import { IProduct, IProductType } from "../../../types";
export const FetchProduct = 'FETCH_PRODUCTS';
export const FetchProductSuccess = 'FETCH_PRODUCTS_SUCCESS';
export const FetchProductFailure = 'FETCH_PRODUCTS_FAILURE';
export const CreateSurfboard = 'CREATE_SURFBOARD';

export interface ICreateSurfboardAction extends Action<typeof CreateSurfboard> {
  payload: {
    id: string
  };
};
export interface IFetchProductAction extends Action<typeof FetchProduct> {
  payload: {
      id?: string | null;
      active?: boolean | null;
      type?: IProductType | null;
  },
}

export interface IFetchProductFailureAction
  extends Action<typeof FetchProductFailure> {
  payload: Error;
}

export interface IFetchProductSuccessAction
  extends Action<typeof FetchProductSuccess> {
  payload: IProduct[];
}

export type ShelfActions =
  | IFetchProductAction
  | IFetchProductFailureAction
  | IFetchProductSuccessAction;
