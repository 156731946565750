
import {loadStripe} from '@stripe/stripe-js';
import { Axios } from 'axios-observable';
import { map } from 'rxjs/operators';
import { ApiBaseUri, StripePublishingKey } from '../constants';
import { CustomSurfboardSaveResponse } from '../store/customOrder/types';
import { CheckoutLineItem } from '../types/CheckoutLineItem';

export class StripeCheckoutService {


  static getCheckoutSession(items: CheckoutLineItem[]) {
    return Axios.put<CustomSurfboardSaveResponse>(
      `${ApiBaseUri}api/v1/Orders/`, {items}).pipe(map(res => res.data));
  }

  static redirectToCheckout(sessionId: string) {  
    loadStripe(StripePublishingKey).then((stripe) => {
      if (stripe)
      stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as argument here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId
      })
    });
  }

  
}


