import React from 'react';
import { connect } from 'react-redux';
import { loadCart, processCartProductQuantityUpdate, removeProduct } from '../../../store/cart/actions';
import CartProduct from '../cartProduct/CartProduct';
import { formatPrice } from '../../../utils/priceFormat';
import Shipping from '../shipping/shipping';

import './style.scss';
import { SubmitButton } from '../../common/button/submit';
import { Action, compose } from 'redux';
import { ActiveCheckoutPage, ICheckoutState } from '../store/state';
import * as Actions from '../store/actions';
import { BaseComponent } from '../../../utils/core/baseComponent';
import { IShippingAddress } from '../store/types/address';
import { checkoutReducer } from '../store/reducers';
import { injectReducer, injectSaga } from 'redux-injectors';
import { cartReducer } from '../../../store/cart/reducer';
import { ICartState, ITotalState } from '../../../store/cart/state';
import { checkoutSagas } from '../store/sagas';
import { IRootState } from '../../../store/state';
import { IRate, IValidationResults } from '../store/types';
import ShippingRatesPage from '../rates/shippingRatesPage';
import { BabelLoading } from 'react-loadingg';
import { loadingStyle } from '../../../utils/commonStyles';
import { IProduct, SizeValues } from '../../../types';
import { cartSagas } from '../../../store/cart/sagas';
import { submitSubscribe } from '../../common/subscribe/store';

export interface IFloatCartActionProps {
  removeProduct: (product: IProduct) => void;
  submitShippingAddress: (addy: IShippingAddress) => void;
  setSelectedShippingRate: (rateId: string | undefined) => void;
  changeCheckoutPage: (activePage: ActiveCheckoutPage) => void;
  submitOrder: () => void;
  setAddress: (address: IShippingAddress) => void;
  updateCartProductQuantity: (productId: string, quantity: number, size?: SizeValues, ) => void;
  loadCart: () => void;
  submitSubscribe: (email: string, firstName?: string, lastName?: string) => void;
}

export interface IFloatCartMapState {
  cartProducts: any[];
  newProduct: any;
  checkout: ICheckoutState;
  validationResults: IValidationResults;
  rates: IRate[];
  address: IShippingAddress;
  activePage: ActiveCheckoutPage;
  cartTotal: ITotalState;
  isLoading?: boolean;
}

export type IFloatCartProps = IFloatCartActionProps & IFloatCartMapState;

class FloatCart extends BaseComponent<IFloatCartProps> {

  state = {
    isOpen: false,
  };

  openFloatCart = () => {
    this.setState({ isOpen: true });
  };

  closeFloatCart = () => {
    this.setState({ isOpen: false });
  };

  addProduct = (product: any) => {
    const cartProducts = this.props.cartProducts.map((e: any) => ({...e}));
    let productAlreadyInCart = false;

    cartProducts.forEach((cp: any) => {
      if (cp.id === product.id) {
        cp.quantity += product.quantity;
        productAlreadyInCart = true;
      }
    });

    if (!productAlreadyInCart) {
      cartProducts.push(product);
    }

    this.openFloatCart();
  };

  removeProduct = (product: any) => {
    const cartProducts = this.props.cartProducts.map((e: any) => ({...e}));

    const index = cartProducts.findIndex((p: any) => p.id === product.id);
    if (index >= 0) {
      cartProducts.splice(index, 1);
    }
  };

  proceedToShipping = () => {
    const {
      productQuantity,
    } = (this.props as any).cartTotal;

    if (!productQuantity) {
      // alert('Add some product in the cart!');
    } else {
      this.toggleShipping(true);
    }
  };

  toggleShipping = (value?: boolean) => {
    let newValue = !this.showShipping;
    if (value !== null) newValue = !!value;
    this.props.changeCheckoutPage(newValue ? 'shipping' : undefined);
  }

  private validateShipping = (address: IShippingAddress) => {
    this.props.submitShippingAddress(address);
    // this.props.changeCheckoutPage('rateSelection')
  }

  private submitOrder = () => {
    this.props.submitOrder();
  }
  
  private get showCart(): boolean {
    return !this.props.activePage && !!this.state.isOpen;
  }

  private get showShipping(): boolean {
    return this.props.activePage === 'shipping';
  }

  private get showRates(): boolean {
    return this.props.activePage === 'rateSelection';
  }

  render(): any {
    const { cartTotal, cartProducts, removeProduct } = this.props;

    const products = cartProducts.map((p: IProduct) => (
        <CartProduct product={p} 
          updateProductQuantity={this.props.updateCartProductQuantity} 
          removeProduct={removeProduct} key={p.id+p.size} />
      ));

    let rootClasses = ['float-cart'];
    let shippingClasses = ['shipping'];

    if (!this.showCart) {
      shippingClasses.push('shipping-open');
      if (!!this.state.isOpen) rootClasses.push('float-cart-pushed');
    }
    else if (!!this.state.isOpen) {
      rootClasses.push('float-cart--open');
    }

    const footer = <div className="float-cart__footer">
    <div className="sub">SUBTOTAL</div>
    <div className="sub-price">
      <p className="sub-price__val">
        {`${cartTotal.currencyFormat} ${formatPrice(
          cartTotal.totalPrice,
          cartTotal.currencyId
        )}`}
        </p>
      </div>
      <SubmitButton onClick={() => this.proceedToShipping()} text='To Shipping'></SubmitButton>
    </div>;



    return (
      <>
        {this.props.isLoading && <BabelLoading style={loadingStyle} speed={undefined as any} color="" size="" />}
      {/* If cart is closed, show bag with quantity of product and open cart action */}
      {!this.state.isOpen && (
        <span
          onClick={() => this.openFloatCart()}
          className="bag bag--float-cart-closed"
        >
          <span className="bag__quantity">{cartTotal.productQuantity}</span>
        </span>
      )}
      <div className={rootClasses.join(' ')}>
      
        {/* If cart open, show close (x) button */}
        {this.state.isOpen && (
          <div
            onClick={() => this.closeFloatCart()}
            className="float-cart__close-btn"
          >
            X
          </div>
        )}
        <div className="float-cart__content">
          <div className="float-cart__header">
            <span className="bag">
              <span className="bag__quantity">{cartTotal.productQuantity}</span>
            </span>
            <span className="header-title">Cart</span>
          </div>

          <div className="float-cart__shelf-container">
            {products}
            {!products.length && (
              <p className="shelf-empty">
                Empty <br />
              </p>
            )}
          </div>
              {footer}
          
        </div>
        {this.showShipping && <Shipping 
          address={this.props.address}
          validationResults={this.props.validationResults} 
          onContinue={(a) => this.validateShipping(a)} 
          onBackClick={() => this.toggleShipping(false)}
          onSaveAddress={this.props.setAddress}
          className={shippingClasses.join(' ')}
          onSubscribeToMailingList={action => this.props.submitSubscribe(action.payload.email, action.payload.firstName, action.payload.lastName)}></Shipping>}
          {this.showRates && <ShippingRatesPage 
          rates={this.props.rates} 
          onBackClick={() => this.toggleShipping(true)}
          onSubmit={() => this.submitOrder()}
          onShippingRateSelected={this.props.setSelectedShippingRate} 
          className={shippingClasses.join(' ')}></ShippingRatesPage>}
      </div>
      </>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  cartProducts: state.cart.products,
  cartTotal: state.cart.total,
  rates: state.checkout.shippingRates,
  validationResults: state.checkout.shippingValidationResults,
  activePage: state.checkout.activePage,
  address: state.checkout.address,
  isLoading: state.checkout.isLoading,
});

const mapDispatchToProps = {
  submitShippingAddress: Actions.submitShippingAddress,
  setSelectedShippingRate: Actions.setSelectedShippingRate,
  changeCheckoutPage: Actions.changeCheckoutPage,
  submitOrder: Actions.submitOrder,
  removeProduct,
  setAddress: Actions.setAddress,
  updateCartProductQuantity: processCartProductQuantityUpdate,
  loadCart: loadCart,
  submitSubscribe: submitSubscribe,
};


export default compose<React.ComponentType>(
  injectReducer({
    key: 'checkout',
    reducer: checkoutReducer as React.Reducer<ICheckoutState, Action>,
  }),
  injectReducer({
    key: 'cart',
    reducer: cartReducer as React.Reducer<ICartState, Action>,
  }),
  injectSaga({ key: 'checkout', saga: checkoutSagas }),
  injectSaga({key: 'cart', saga: cartSagas}),
  connect(mapStateToProps, mapDispatchToProps),
)(FloatCart);