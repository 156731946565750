import { FunctionComponent } from 'react';
import React from 'react';

  type ImageProps = {
    images?: string[],
    title?: string,
    alt?: string,
  }

  function getSrcSet(images: string[]): string {
    if (!images || (typeof images.forEach !== 'function')) return '';
    const findNumber = (img: string) => {
      const matches = /(\d+)(?!.*\d)/.exec(img);
        return matches ? Number(matches[0]) : 200;
    }
    // const getSizeSuffix = (img: string) => {
    //     const matches = /(\d+)(?!.*\d)/.exec(img);
    //     return `${matches ? matches[0] : 200}x,\n`;
    // };
    const imagesCopy = [...images];

    let joined = '';
    imagesCopy
      .sort((a, b) => findNumber(a) - findNumber(b)) 
      .forEach((src, i, _) => {
      joined += `${src} ${i}x,\n`
    });
    return joined;
  }
  
  // No need to define the defaultProps property
  export const ReactiveImageComponent: FunctionComponent<ImageProps> = ({ images, title, alt }) => {
    const srcSet = images ? getSrcSet(images): '';
  
    return  <img data-srcset={srcSet} srcSet={srcSet} alt={alt} title={title} />;
  }
