import { Dimensions } from ".";

export interface IAvailableDimensions {
    stokeMachine: Dimensions[];
    shredder: Dimensions[];
    pinhead: Dimensions[];
    log: Dimensions[];
}

export const availableDimensions: IAvailableDimensions = {
    stokeMachine: [
        {
            lengthInches: 58,
            widthInches: 17.75,
            thicknessInches: 2,
            liters: 22,
        },
        {
            lengthInches: 60,
            widthInches: 18.25,
            thicknessInches: 2.25,
            liters: 25,
        },
        {
            lengthInches: 62,
            widthInches: 18.875,
            thicknessInches: 2.25,
            liters: 27,
        },
        {
            lengthInches: 64,
            widthInches: 19.5,
            thicknessInches: 2.5,
            liters: 29,
        },
        {
            lengthInches: 66,
            widthInches: 20.25,
            thicknessInches: 2.5,
            liters: 32,
        },
        {
            lengthInches: 68,
            widthInches: 20.75,
            thicknessInches: 2.5,
            liters: 35,
        },
        {
            lengthInches: 70,
            widthInches: 21.25,
            thicknessInches: 2.75,
            liters: 38,
        },
        {
            lengthInches: 72,
            widthInches: 22,
            thicknessInches: 2.75,
            liters: 42,
        },
        {
            lengthInches: 74,
            widthInches: 22.5,
            thicknessInches: 2.75,
            liters: 22,
        },

    ],
    shredder: [
        {
            lengthInches: 58,
            widthInches: 18.25,
            thicknessInches: 2.25,
            liters: 22,
        },
        {
            lengthInches: 60,
            widthInches: 18.75,
            thicknessInches: 2.25,
            liters: 24,
        },
        {
            lengthInches: 62,
            widthInches: 19.25,
            thicknessInches: 2.25,
            liters: 26,
        },
        {
            lengthInches: 64,
            widthInches: 20,
            thicknessInches: 2.5,
            liters: 29,
        },
        {
            lengthInches: 66,
            widthInches: 20.75,
            thicknessInches: 2.5,
            liters: 32,
        },
        {
            lengthInches: 68,
            widthInches: 21.25,
            thicknessInches: 2.5,
            liters: 35,
        },
        {
            lengthInches: 70,
            widthInches: 21.75,
            thicknessInches: 2.75,
            liters: 38,
        },
        {
            lengthInches: 72,
            widthInches: 22.5,
            thicknessInches: 2.75,
            liters: 42,
        },
        {
            lengthInches: 74,
            widthInches: 23.25,
            thicknessInches: 2.875,
            liters: 46,
        },
    ],
    pinhead: [
        {
            lengthInches: 78,
            widthInches: 18.875,
            thicknessInches: 2.25,
            liters: 33,
        },
        {
            lengthInches: 80,
            widthInches: 19.25,
            thicknessInches: 2.25,
            liters: 35,
        },
        {
            lengthInches: 82,
            widthInches: 19.875,
            thicknessInches: 2.5,
            liters: 38,
        },
        {
            lengthInches: 84,
            widthInches: 20.25,
            thicknessInches: 2.5,
            liters: 41,
        },
        {
            lengthInches: 86,
            widthInches: 20.875,
            thicknessInches: 2.5,
            liters: 44,
        },
        {
            lengthInches: 88,
            widthInches: 21.25,
            thicknessInches: 2.75,
            liters: 47,
        },
        {
            lengthInches: 90,
            widthInches: 21.875,
            thicknessInches: 2.75,
            liters: 50,
        },
        {
            lengthInches: 92,
            widthInches: 22.25,
            thicknessInches: 2.875,
            liters: 53,
        },
    ],
    log: [
        {
            lengthInches: 102,
            widthInches: 21.5,
            thicknessInches: 2.5,
            liters: 55,
        },
        {
            lengthInches: 104,
            widthInches: 21.875,
            thicknessInches: 2.5,
            liters: 58,
        },
        {
            lengthInches: 106,
            widthInches: 22.25,
            thicknessInches: 2.5,
            liters: 61,
        },
        {
            lengthInches: 108,
            widthInches: 22.875,
            thicknessInches: 2.75,
            liters: 64,
        },
        {
            lengthInches: 110,
            widthInches: 23.25,
            thicknessInches: 2.75,
            liters: 68,
        },
        {
            lengthInches: 112,
            widthInches: 23.5,
            thicknessInches: 2.875,
            liters: 72,
        },
        {
            lengthInches: 114,
            widthInches: 24,
            thicknessInches: 2.875,
            liters: 76,
        },
        {
            lengthInches: 116,
            widthInches: 24.5,
            thicknessInches: 2.875,
            liters: 80,
        },
        {
            lengthInches: 118,
            widthInches: 25,
            thicknessInches: 3,
            liters: 84,
        },
        {
            lengthInches: 120,
            widthInches: 25.25,
            thicknessInches: 3,
            liters: 88,
        },
        {
            lengthInches: 122,
            widthInches: 25.875,
            thicknessInches: 3,
            liters: 92,
        }
    ]
};