import { makeStyles } from "@material-ui/core";
import { SwatchesPicker, SketchPicker, ColorResult } from 'react-color';
import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import CollectionsIcon from '@material-ui/icons/Collections';
const useStyles = makeStyles({
    picker: {
      justifyContent: 'center',
      margin: 'auto'
    },
    toggleButtons: {
      margin: 'auto',
      padding: '20px 0',
      // paddingLeft: '120px'
    }
});

interface ColorPickerRenderProps {
    onChange: (update: string) => void;
}
  
const ColorPicker: React.FC<ColorPickerRenderProps> = ({onChange}) => {
    const classes = useStyles();
    const [activePicker, setActivePicker] = React.useState<'sketch' | 'swatch'>('swatch');
    const [color, setColor] = React.useState('none');

    const toolChange = (_event: React.MouseEvent<HTMLElement>, update: string | null) => {
        if (update !== null) {
          const nValue = update && update.toLowerCase() === 'sketch' ? 'sketch' : 'swatch';
          setActivePicker(nValue);
        }
      };

      const handleChangeComplete = (color: ColorResult) => {
        setColor(color.hex);
        onChange(color.hex);
      };

    const pickerTx = activePicker === 'swatch' ? 
    <SwatchesPicker color={ color }
     onChangeComplete={ handleChangeComplete }
     className={classes.picker}/> : 
     <SketchPicker color={ color }
     onChangeComplete={ handleChangeComplete } className={classes.picker}/>;
    // const [pictures, setPictures] = useState([] as File[]);
    // const isLast = activeStep === (state.stages.length - 1);
    // const stage = state.stages[activeStep];
    return (<> 
    <ToggleButtonGroup
            className={classes.toggleButtons}
            value={activePicker}
            exclusive
            onChange={toolChange}
            aria-label="text alignment"
          >
            <ToggleButton value="swatch" aria-label="centered">
              <CollectionsIcon />
            </ToggleButton>
            <ToggleButton value="sketch" aria-label="left aligned">
              <ColorLensIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          {pickerTx}
           </>);
}
export default ColorPicker;
