import React from 'react';
import CategoryItem from './CategoryItem';
import './style.scss';
import { slide as Menu } from 'react-burger-menu';
import { connect } from 'react-redux';
import { BaseComponent } from '../../utils/core/baseComponent';
import { IRootState } from '../../store/state';

export interface ICategoryListProps {
  isMobile?: boolean;
  isLoading?: boolean;
}

export interface ICategoryListState {
  isMenuOpen?: boolean;
}

export const staticCategoryPages = [
  'Home',
  'Create',
  'Contact',
  'About',
];

class CategoryList extends BaseComponent<ICategoryListProps, ICategoryListState> {

  constructor(props: ICategoryListProps) {
    super(props);

    this.state = {
      isMenuOpen: undefined,
    };
  }

  private categorys = [
    { caption: 'Home'},
    { caption: 'Create' },
    { caption: 'Surfboards'},
    { caption: 'Apparel'},
    { caption: 'Contact'},
    { caption: 'About'},
  ];
  private listItems: JSX.Element[] = [];

  componentDidUpdate(prevProps: ICategoryListProps) {
    // Typical usage (don't forget to compare props):
    if (prevProps.isLoading && !this.props.isLoading) {
      this.setState({isMenuOpen: false});
    } else if (this.state.isMenuOpen !== undefined) {
      this.setState({isMenuOpen: undefined});
    }
  }

  private onItemClick() {
    this.setState({isMenuOpen: false})
  }

  render() {
    this.listItems = this.categorys.map((item, i) => <CategoryItem onStaticItemClick={() => this.onItemClick()} isMobile={this.props.isMobile} caption={item.caption} dropdown={false} key={i}/>)
    // the rest is the same...
  
    if (this.props.isMobile) {
      return (
        <div className='categories-container mobile'>
          <Menu pageWrapId='main-id' isOpen={this.state.isMenuOpen}>
            {this.listItems}
          </Menu>
        </div>
      );
    } else {
      return (
        <div className='categories-container'>
          <nav className='category-list'>
            <ul className='list-element'>
            {this.listItems}
            </ul>
          </nav>
      </div>
      );
    }
  }
}

  const mapStateToProps = (state: IRootState) => ({
    isMobile: state.global.isMobile,
    isLoading: state.shelf.isLoading
  });
  
  export default connect(
    mapStateToProps,
    { }
  )(CategoryList);