import { FunctionComponent } from 'react';
import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import { RouteComponentProps } from 'react-router-dom';

const useStyles = makeStyles({
    container: {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        padding: "0 24px"
    },
    header: {
        fontSize: "24px",
        fontWeight: 600,
        paddingTop: '24px'
    }
  });

  interface FailureProps extends RouteComponentProps {
    id: string;
  }

  
  // No need to define the defaultProps property
  export const Failure: FunctionComponent<FailureProps> = ({location}) => {

    const classes = useStyles();
    const header = 'Uh oh, something went wrong while processing your order. Please try again.'
    

      return <div className={classes.container}>
          <Typography className={classes.header} color="textPrimary"> {header}
    </Typography>

    </div>
  }