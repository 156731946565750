import { Action } from "redux";
import { IProduct, SizeValues } from "../../types";
import { ICartState } from "./state";

export const LoadCart = 'LOAD_CART';
export const LoadCartSuccess = 'LOAD_CART_SUCCESS';
export const LoadCartFailed = 'LOAD_CART_FAILED';
export const AddToCart = 'ADD_TO_CART';
export const RemoveProduct = 'REMOVE_PRODUCT';
export const UpdateCart = 'UPDATE_CART';
export const ClearCart = 'CLEAR_CART';
export const SetPendingOrder = 'SET_PENDING_ORDER';
export const UpdateTotal = 'UPDATE_TOTAL';
export const UpdateCartProductQuantity = 'UPDATE_CART_PRODUCT_QUANTITY';
export const ProcessCartProductQuantityUpdate = 'PROCESS_CART_PRODUCT_QUANTITY_UPDATE';

export interface IUpdateProductQuantityAction extends Action<typeof UpdateCartProductQuantity> {
    quantity: number;
    productId: string;
    size?: SizeValues;
  }

export interface IProcessProductQuantityUpdateAction extends Action<typeof ProcessCartProductQuantityUpdate> {
  quantity: number;
  productId: string;
  size?: SizeValues;
}

export interface IUpdateTotalAction extends Action<typeof UpdateTotal> {
    payload: any,
}

export interface ISetPendingOrderAction extends Action<typeof SetPendingOrder> {
    payload: { orderId: string };
}

export interface IClearCart extends Action<typeof ClearCart> {
    payload: {orderId: string}
}
export interface IAddToCartAction extends Action<typeof AddToCart> {
    payload: IProduct;
}

export interface ILoadCartAction extends Action<typeof LoadCart> {
}

export interface ILoadCartSuccessAction extends Action<typeof LoadCartSuccess> {
    payload: ICartState,
}

export interface ILoadCartFailedAction extends Action<typeof LoadCartFailed> {
    payload: Error,
}

export interface IRemoveProductAction extends Action<typeof RemoveProduct> {
    payload: IProduct
}

export type CartActions =
  | IAddToCartAction
  | ILoadCartAction
  | ILoadCartSuccessAction
  | ILoadCartFailedAction
  | IRemoveProductAction
  | IClearCart
  | ISetPendingOrderAction
  | IUpdateTotalAction
  | IUpdateProductQuantityAction
  | IProcessProductQuantityUpdateAction;