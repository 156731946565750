import React from 'react';
import { Provider } from 'react-redux';

import { configureAppStore } from './store/configureStore';
import history from './utils/history';

const store = configureAppStore(history);


const Root = ({ children }: {children: any}) => (<Provider store={store}>{children}</Provider>);

export default Root;
