import { BlogActions, FetchBlogListSuccess, FetchBlogPostSuccess } from "./actionTypes";
import { IBlogState, initialBlogState } from "./state";

export function blogReducer(state: IBlogState = initialBlogState, action: BlogActions): IBlogState {
    switch (action.type) {
        case FetchBlogListSuccess: {
            const post = state.post || action.payload[0];

            return {
                ...state,
                list: action.payload,
                post,
            }
        }
        case FetchBlogPostSuccess: {
            return {
                ...state,
                post: action.payload,
            }
        }
    }

    return state;
}