import { Reducer } from 'redux'
import { CustomSurfboardCreateState, CustomOrderActionTypes, Stage, FieldType, Field } from './types'
import { FieldUpdateEvent, StageChangeEvent } from './actions'
import { getStages, boardInfoMap, Names, finBoxColorField, leashFields, leashPlugColorField } from '../../types/customOrderProcess'

export const getInitialCustomSurfboardCreateState = (): CustomSurfboardCreateState => ({
  stages: getStages(),
  activeStep: 0,
  options: {
    Email: {
      Value: '',
      IsRequired: true
    },
    Shape: {
      Value: '',
      IsRequired: true
    },
    FinType: {
      Value: '',
      IsRequired: true
    },
    Dimensions: {
      Value: null,
      IsRequired: true
    },
    FoamType: {
      Value: '',
      IsRequired: false
    },
    GlassType: {
      Value: '',
      IsRequired: false
    },
    LogoName: {
      Value: null,
      IsRequired: false
    },
    LogoNotes: {
      Value: null,
      IsRequired: false
    },
    LeashPlugType: {
      Value: null,
      IsRequired: true
    }
  },
  id: (((1+Math.random())*0x1000000)|0).toString(16).substring(1)
});

const reducer: Reducer<CustomSurfboardCreateState> = (state = getInitialCustomSurfboardCreateState(), action) => {
  switch (action.type) {
    case CustomOrderActionTypes.UpdateField: {
      return updateFieldReducer(state, action as any);
    }
    case CustomOrderActionTypes.ChangeStage: {
      return stageChangeReducer(state, action as any);
    }
    case CustomOrderActionTypes.SetStages: {
      return setStagesReducer(state, action as any);
    }
    case CustomOrderActionTypes.ResetCreate: {
      return {
        ...getInitialCustomSurfboardCreateState(),
      }
    }
    default: {
      return state
    }
  }
}

const setStagesReducer = (state = getInitialCustomSurfboardCreateState(), action: {payload: Stage[]}) => {
  return { ...state,  stages: action.payload };
}

const stageChangeReducer = (state = getInitialCustomSurfboardCreateState(), action: {payload: StageChangeEvent}) => {
  const increment = () => {
    return (state.stages.length - 1) > state.activeStep ? state.activeStep + 1 : state.activeStep;
  }

  const decrement = () => {
    return 0 < state.activeStep ? state.activeStep - 1 : state.activeStep;
  }

  const newStep = action.payload.type === 'next' ? increment() : decrement();
  return { ...state, activeStep: newStep }
};

const updateFieldReducer = (state = getInitialCustomSurfboardCreateState(), action: {payload: FieldUpdateEvent}): CustomSurfboardCreateState => {

  let stages = updateStages(state, action);


  return { ...state, options: {
    ...state.options,
    [action.payload.id]: { 
      ...(state.options as any)[action.payload.id],
       Value: action.payload.newValue
    }
  },
  stages
  }
};

const updateFoamTypeStage = (stages: Stage[], action: {payload: FieldUpdateEvent}) => {
  let stage: Stage;

  if (action.payload.newValue === Names.FoamType.EPS) {
    stage = {
     ...stages[action.payload.stageIndex],
     fields: [
       { 
         id: 'FoamType',
         caption: 'Select a Foam Type', type: FieldType.dropdown, 
         options: [
             'PU',
             Names.FoamType.EPS
         ],
         default: 'PU',
         isRequired: true },
         { 
           id: 'GlassType',
           caption: 'Select a Glass Type', type: FieldType.dropdown, 
           options: [
            Names.GlassType.Epoxy,
          ],
           isRequired: true }
     ]
   };
 } else {
   stage = getStages()[action.payload.stageIndex];
 }

 return stage;
}

const finConstructionTypeField = { 
  id: 'FinConstructionType',
  caption: 'Glassed-in or Swappable fins?', type: FieldType.dropdown, 
  options: [
      Names.FinConstruction.GlassIn,
      'Swappable',
  ],
  isRequired: true };


const updateLogoStage = (stages: Stage[], action: {payload: FieldUpdateEvent}) => {
  let stage: Stage;

  if (action.payload.newValue) {
    const orig = stages[action.payload.stageIndex];
    stage = {
     ...orig,
     fields: [
        orig.fields[0],
        { 
          id: 'LogoNotes',
          caption: 'Describe where the logo should go', 
          type: FieldType.input,
          isRequired: false
        },
        orig.fields[1]
     ]
   };
 } else {
   stage = getStages()[action.payload.stageIndex];
 }

 return stage;
}
const updateFinStage = (stages: Stage[], stageIndex:number, action: {payload: FieldUpdateEvent}) => {
  let stage: Stage;

  if (action.payload.newValue) {
    const orig = getStages()[stageIndex];
    const boardInfo = boardInfoMap[action.payload.newValue];
    let fields: Field[] = [];

    if (boardInfo.finOptions.enableGlassInOption) {
      fields = [finConstructionTypeField];
    } else {
      fields = [
        orig.fields[0],
        { 
          id: 'FinConfiguration',
          caption: 'Select Fin Configuration', type: FieldType.dropdown, 
          options: [
            ...boardInfo.finOptions.boxOptions
          ],
          default: boardInfo.finOptions.boxOptions[0],
          isRequired: true },
          finBoxColorField
      ]
    }
    stage = {
     ...orig,
     fields: [
      ...fields,
      ...leashFields
     ],
   };
 } else {
   stage = stages[stageIndex];
 }

 return stage;
}


const updateFinStageOnFinConstructionSelection = (state: CustomSurfboardCreateState, stages: Stage[], action: {payload: FieldUpdateEvent}) => {
  let stage: Stage;
  const stageIndex = stages.findIndex(v => v.id === 'finstage');

  if (action.payload.newValue) {
    const orig = stages[stageIndex];
    const boardInfo = boardInfoMap[state.options.Shape.Value!];
    let fields: Field[] = [];

    if (action.payload.newValue === Names.FinConstruction.GlassIn) {
      fields = [
        finConstructionTypeField,
        { 
          id: 'FinConfiguration',
          caption: 'Select Fin Configuration', type: FieldType.dropdown, 
          options: [
            ...boardInfo.finOptions.glassInOptions || []
          ],
          default: boardInfo.finOptions.glassInOptions![0],
          isRequired: true }
      ]
    } else {
      fields = [
        finConstructionTypeField,
        getStages()[stageIndex].fields[0],
        { 
          id: 'FinConfiguration',
          caption: 'Select Fin Configuration', type: FieldType.dropdown, 
          options: [
            ...boardInfo.finOptions.boxOptions
          ],
          default: boardInfo.finOptions.boxOptions[0],
          isRequired: true },
          finBoxColorField
          
      ]
    }

    const leash = [...leashFields];
    if (state.options.LeashPlugType.Value === Names.Leash.Plug) leash.push(leashPlugColorField);

    stage = {
     ...orig,
     fields: [
      ...fields,
      ...leash
     ],
   };
 } else {
   stage = stages[stageIndex];
 }

 return stage;
}

const updateOnLeashPlugTypeSelection = (state: CustomSurfboardCreateState, stages: Stage[], action: {payload: FieldUpdateEvent}) => {
  let stage: Stage;
  const stageIndex = stages.findIndex(v => v.id === 'finstage');

  if (action.payload.newValue) {
    const orig = stages[stageIndex];
    let fields: Field[] = [];

    if (action.payload.newValue === Names.Leash.Plug) {
      fields = [
        ...orig.fields,
        leashPlugColorField
      ]
    } else {
      let origFields = [...orig.fields];
      const remove = orig.fields.findIndex(f => f.id === leashPlugColorField.id);
      if (remove >= 0) origFields.splice(remove, 1);

      fields = origFields;
    }
    stage = {
     ...orig,
     fields,
   };
 } else {
   stage = stages[stageIndex];
 }

 return stage;
}

const updateColorSelection = (state: CustomSurfboardCreateState, stages: Stage[], action: {payload: FieldUpdateEvent}) => {
  let stage: Stage;
  const stageIndex = stages.findIndex(v => v.id === 'colorstage');
  let fields: Field[] = [];

  if (action.payload.newValue) {
    const orig = stages[stageIndex];
    switch (action.payload.newValue) {
      case Names.Colors.NoColor: 
        fields.push(orig.fields[0]);
        break;
      case Names.Colors.SolidColor:
        fields = [
          orig.fields[0],
          {
            id: 'ColorModel',
            caption: 'Choose Color',
            type: FieldType.colorModel,
            isRequired: false,
            innerColorField: 'SingleColorPicker',
            outerColorField: 'SingleColorPicker'
          },
          { 
            id: 'SingleColorPicker',
            caption: 'Solid Color', 
            type: FieldType.colorPicker,
            isRequired: false
        }
        ];
        break;
      case Names.Colors.TopAndBottomColors:
        fields = [
          orig.fields[0],
          {
            id: 'ColorModel',
            caption: 'Top',
            type: FieldType.colorModel,
            isRequired: false,
            innerColorField: 'TopColorPicker',
            outerColorField: 'BottomColorPicker'
          },
          { 
            id: 'TopColorPicker',
            caption: 'Top', 
            type: FieldType.colorPicker,
            isRequired: false
        },
        {
          id: 'ColorModel',
          caption: 'Bottom',
          type: FieldType.colorModel,
          isRequired: false,
          innerColorField: 'BottomColorPicker',
          outerColorField: 'BottomColorPicker'
        },
        { 
          id: 'BottomColorPicker',
          caption: 'Bottom', 
          type: FieldType.colorPicker,
          isRequired: false
      }
        ];
        break;
    }
    stage = {
      ...orig,
      fields
    };
  } else {
    stage = stages[stageIndex];
  }

 return stage;
}


const updateStages = (state: CustomSurfboardCreateState, action: {payload: FieldUpdateEvent}) => {
  const stages = [...state.stages];
  let stage: Stage | null = null;
  switch (action.payload.id) {
    case 'FoamType': {
      stage = updateFoamTypeStage(stages, action);
      stages.splice(action.payload.stageIndex, 1, stage);
      break;
    }
    case 'LogoName': {
      stage = updateLogoStage(stages, action);
      stages.splice(action.payload.stageIndex, 1, stage);
      break;
    }
    case 'Shape': {
      const stageIndex = stages.findIndex(v => v.id === 'finstage');
      stage = updateFinStage(stages,stageIndex, action);
      stages.splice(stageIndex, 1, stage);
      break;
    }
    case 'LeashPlugType': {
      stage = updateOnLeashPlugTypeSelection(state, stages, action);
      stages.splice(action.payload.stageIndex, 1, stage);
      break;
    }
    case 'FinConstructionType': {
      stage = updateFinStageOnFinConstructionSelection(state, stages, action);
      stages.splice(action.payload.stageIndex, 1, stage);
      break;
    }
    case 'ColorPattern': {
      stage = updateColorSelection(state, stages, action);
      stages.splice(action.payload.stageIndex, 1, stage);
      break;
    }
  }
  return stages;
}

export { reducer as customOrderReducer }
