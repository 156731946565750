import { Stack } from "@fluentui/react";
import { Button, TextField } from "@material-ui/core";
import React, { useState } from 'react';
import { connect } from "react-redux";
import { IRootState } from "../../../store/state";
import { submitSubscribe } from "./store";

export interface ISubscribeProps {
    submitSubscribe: (email: string) => void,
    buttonText?: string,
    className?: string,
}

export function SubscribeField(props: ISubscribeProps) {
    const [email, setEmail] = useState<string>('');

    const onSubmit = () => {email && props.submitSubscribe(email)};

    return <Stack className={props.className}>
    <TextField
        autoFocus
        id="subscribe-field"
        label="Email"
        inputProps={{ "autoComplete": "email"}}
        onChange={(event) => setEmail(event.target.value)}
        />
        <Button onClick={onSubmit} >
        { props.buttonText || 'Join our mailing list'}
        </Button>
    </Stack>
}

const mapStateToProps = (_state: IRootState) => ({
  });

const mapDispatchToProps = {
    submitSubscribe
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeField);