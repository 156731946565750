import { DetailsList,Selection, IColumn, mergeStyles, SelectionMode } from '@fluentui/react';
import { IRate } from '../store/types';
import * as React from 'react';
import './listOverrides.scss';
import { ColumnKeys, ratesColumns } from './columns';

export interface IShippingRatesProps {
    rates: IRate[],
    onSelectShippingRate: (rateId: string | undefined) => void
}

export function renderItemColumn(
    item: IRate,
    _index: number | undefined,
    column: IColumn | undefined,
  ): JSX.Element {
    if (!column) return <></>;
  
    const fieldContent = (item as any)[column.fieldName ?? ''] as string;
  
    switch (column.key as ColumnKeys) {
        case 'description': 
        var description = `${item.provider} ${item.servicelevel.name}`;
        if (item.durationTerms) description += ` (${item.durationTerms})`;
    return <span>{description}</span>
      default:
        return <span>${fieldContent}</span>;
    }
  }
  
  const classes = {
      list: mergeStyles({
          marginTop: '36px',
        // margin: '0 60px',
      })
  }

  function itemInvoked(item?: any, index?: number | undefined, ev?: Event | undefined): void {
  }

export function ShippingRatesList(props: IShippingRatesProps): JSX.Element {
    const onSelectShippingRate = () => {
        const rate = getSelectionDetails();
        props.onSelectShippingRate(rate ? rate.objectId : undefined);
    }

    const selection = new Selection({
        onSelectionChanged: onSelectShippingRate
      });

     const getSelectionDetails = ():  IRate | undefined => {
        const selectionCount = selection.getSelectedCount();
        
        return selectionCount > 0 ? (selection.getSelection()[0] as IRate) : undefined;
      }


    return <DetailsList
    selectionPreservedOnEmptyClick={true}
    className={classes.list}
    columns={ratesColumns}
    items={props.rates}
    isHeaderVisible={false}
    selection={selection}
    selectionMode={SelectionMode.single}
    onRenderItemColumn={renderItemColumn}
    onItemInvoked={itemInvoked}
    setKey="none"></DetailsList>
}