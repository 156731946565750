import { Action, Dispatch } from "@reduxjs/toolkit";
import { IFetchBlogListFailureAction } from "../../components/Blog/store/actionTypes";
import { IGlobalConfiguration } from "./state";

export const UPDATE_IS_MOBILE = 'update_is_mobile';


export const OnAppLoad = 'ON_APP_LOAD';
export const FetchConfiguration = 'FETCH_CONFIGURATION';
export const FetchConfigurationSuccess= 'FETCH_CONFIGURATION_SUCCESS';
export const FetchConfigurationFailed = 'FETCH_CONFIGURATION_FAILED';


export interface IUpdateIsMobileAction extends Action<typeof UPDATE_IS_MOBILE> {
    payload: boolean;
}
export type IOnAppLoadAction = Action<typeof OnAppLoad>;
export type IFetchConfigurationAction = Action<typeof FetchConfiguration>;
export interface IFetchConfigurationSuccessAction extends Action<typeof FetchConfigurationSuccess> {
    payload: IGlobalConfiguration;
}
export type IFetchConfigurationFailedAction = Action<typeof FetchConfigurationFailed>;

export function onAppLoad(): IOnAppLoadAction {
    return {
        type: OnAppLoad
    };
}

export function fetchConfiguration(): IFetchConfigurationAction {
    return {
        type: FetchConfiguration
    };
}

export function fetchConfigurationSuccess(config: IGlobalConfiguration): IFetchConfigurationSuccessAction {
    return {
        type: FetchConfigurationSuccess,
        payload: config,
    }
}

export const updateIsMobile = (isMobile: boolean) => ({
    type: UPDATE_IS_MOBILE,
    payload: isMobile
});

export const handleWindowSizeChange = () => (dispatch: Dispatch) => {
    // this.setState({ width: window.innerWidth });
    dispatch({type: UPDATE_IS_MOBILE, payload:  window.innerWidth <= 900 })
};

export type GlobalActions = 
IUpdateIsMobileAction |
IFetchConfigurationAction | 
IFetchConfigurationSuccessAction | 
IFetchConfigurationFailedAction;