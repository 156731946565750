import { GlobalActions } from '.';
import * as actions from './actions';
import { IGlobalState, initialGlobalState } from './state';

export function globalReducer(state:IGlobalState = initialGlobalState, action: GlobalActions):IGlobalState {
  switch (action.type) {
    case actions.UPDATE_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload
      };
    case actions.FetchConfigurationSuccess:
      return {
        ...state,
        ...action.payload
      } 
    default:
      return state;
  }
}
