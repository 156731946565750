import { call, put, takeLatest } from "@redux-saga/core/effects";
import { FetchConfiguration, fetchConfigurationSuccess, IFetchConfigurationAction, IGlobalConfiguration } from ".";
import { requestWrapper } from "../../utils/requestHelper";

export function* fetchConfigurationSaga(
    _action: IFetchConfigurationAction,
): Generator {
    try {
        const config = (yield call(requestWrapper as any, `api/v1.0/configuration`, {
            params: {
            },
            method: 'GET',
          })) as IGlobalConfiguration;
        yield put(fetchConfigurationSuccess(config));
    } catch (error) {
        yield put({type: 'GLOBALCONFIGFAILURE'});
    }
}

export function* globalSagas(): Generator {
  yield takeLatest(FetchConfiguration, fetchConfigurationSaga);
}