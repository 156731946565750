import React, { Component } from 'react';

import Product from './Product/Product';
interface ProductListProps {
  products: any[];
}

class ProductList extends Component<ProductListProps> {


  componentDidUpdate(props: ProductListProps){
    if (this.props.products !== props.products) {
      this.render();
    }
  }

  render() {

    const products = this.props.products.map(p => {
      return <Product product={p} key={p.id} />;
    });

    return <>
    {products}
    </>
  }
}

export default ProductList;
