
import React, { Component } from 'react';
import logo from '../../assets/images/logos/belmontshroom.png';
import './style.scss';
import Typography from '@material-ui/core/Typography';
import SubscribeField from '../common/subscribe';

class About extends Component {
  render() {
    const description = 'Post-surf, stoke-fueled, and imaginations running high one summer day, we hatched an idea to shape our own boards. We didn\u2019t have high expectations, but to our surprise, Belmont Surf Company was the result of that idea. What started as shaping personal boards in Belmont Shore, Long Beach, CA, has grown into something we\u2019ve only dreamed about.\r\n\r\nWith engineering backgrounds and a growing appreciation for retro-styled surfing, we combined tried-and-true classic shapes with modern crafting techniques. In doing so, we are able to consistently provide you with a good looking yet effective surf craft.';

    const ecoParagraph = 'As we continue to build our little company, we intend to do so following sustainable and environmentally friendly practices. Today we use only eco-friendly, recycled packaging in our shipments and as we grow we hope to incorporate additional eco-friendly materials in our product line.';

    return (
      <div className='about-content'>
        <img className='about-logo' src={logo} alt="Belmont Logo" />
        <Typography className='about-text' color="textPrimary" gutterBottom>
            {description}
        </Typography>
        <Typography className='about-text' color="textPrimary" gutterBottom>
            {ecoParagraph}
        </Typography>
        <SubscribeField />
    </div>
    );
  }
}

export default About;
