import React from 'react';
import celebration_off from '../../assets/gifs/celebration/off_celebration.gif';
import celebration_dance from '../../assets/gifs/celebration/dance_celebration.gif';
import celebration_djk from '../../assets/gifs/celebration/djk_celebration.gif';
import celebration_fb from '../../assets/gifs/celebration/fb_celebration.gif';
import celebration_jc from '../../assets/gifs/celebration/jc_celebration.gif';
import celebration_ny from '../../assets/gifs/celebration/ny_celebration.gif';
import celebration_surf from '../../assets/gifs/celebration/surf_celebration.gif';

import { RouteComponentProps } from 'react-router-dom';
import * as qs from 'qs';
import { connect } from 'react-redux';
import {cartReducer, clearCart, ICartState} from '../../store/cart';
import { IRootState } from '../../store/state';
import { BaseComponent } from '../../utils/core/baseComponent';
import { compose } from 'redux';
import { mergeStyles } from '@fluentui/react';
import { injectReducer } from 'redux-injectors';

const classes = {
    container: mergeStyles({
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        padding: "0 24px"
    }),
    header: mergeStyles({
        fontSize: "24px",
        fontWeight: 600
    }),
    infoText: mergeStyles({
        fontSize: '18px'
    }),
    gif: mergeStyles({
        maxWidth:'375px',
        margin: 'auto'
    }),
  };

  interface SuccessProps extends RouteComponentProps {
    id: string;
    clearCart: (orderId: string) => void;
  }

  const gifs = [
    celebration_dance,
    celebration_djk,
    celebration_fb,
    celebration_jc,
    celebration_ny,
    celebration_off,
    celebration_surf
  ];
  
  class Success extends BaseComponent<SuccessProps, {}> {

    private id: string;
    constructor(props: SuccessProps) {
      super(props);
      this.id = `${qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id}`;

    }

    componentDidMount() {
      setTimeout(() => this.props.clearCart(this.id), 1500);
    }

    render() {
    //Todo - check status of order
    const celebrationGif =  gifs[Math.floor(Math.random() * gifs.length)];


      return <div className={classes.container}>
      <span className={classes.header}>Thank you for your purchase!</span>
      <span className={classes.infoText}>Your order number is: {this.id}</span>
      <img className={classes.gif} src={celebrationGif} alt="Hooray!" />

    </div>
    }
  }

  const mapDispatchToProps = {
    clearCart: clearCart,
  }

  const mapStateToProps = (_state: IRootState) => ({
  });

  export default compose<React.ComponentType>(
    injectReducer({
      key: 'cart',
      reducer: cartReducer,
    }),
    connect(mapStateToProps, mapDispatchToProps),
  )(Success);
  