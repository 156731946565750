import { call, put, select, takeLatest } from "redux-saga/effects";
import { getProductQuantity } from "../../helpers/sizeQuantity";
import { IProduct } from "../../types";
import { requestWrapper } from "../../utils/requestHelper";
import { loadCartFailed, loadCartSuccess, updateCartProductQuantity } from "./actions";
import { ILoadCartAction, IProcessProductQuantityUpdateAction, LoadCart, ProcessCartProductQuantityUpdate } from "./actionTypes";
import { selectCartProduct, selectProductQuantity, selectSizeQuantity } from "./select";
import { ICartState, initialCartState } from "./state";

export function* loadCartSaga(
    _action: ILoadCartAction,
): Generator {
    const localSave = window.localStorage.getItem('state');

    if (localSave) {
        try {

            const cart: ICartState = JSON.parse(localSave).cart;
            if (cart.products && cart.products.length > 0) {
                const { items: responseData } = (yield call(requestWrapper as any, `api/v1/products`, {
                    params: {
                        active: true
                    },
                    method: 'GET',
                  })) as { items: IProduct[]};

    
                const cartProducts = cart.products.map(product => {
                    if (product.type === 'customsurfboard') return {
                        ...product
                    };

                    const freshdata = responseData.find(respItem => respItem.id === product.id);
                    if (freshdata) {
                        const freshQuantity = getProductQuantity(freshdata, product.size);
                        const quantity = Math.min(freshQuantity, product.quantity);

                        if (quantity > 0) {
                            return {
                                ...product,
                                ...freshdata,
                                quantity: quantity
                            }

                        }
                    }
                    return undefined;
                }).filter(e => !!e) as IProduct[];
    
                yield put(loadCartSuccess({
                    ...initialCartState,
                    ...cart,
                    products: cartProducts,
                }));

            }
        } catch (error) {
            yield put(
                loadCartFailed({
                name: 'loadCart Error',
                message: `${error.message}`,
              }),
            );
          }
    }
}

export function* updateQuantitySaga(
    action: IProcessProductQuantityUpdateAction,
): Generator {
    const productQuantity = (yield select(selectProductQuantity(action.productId))) as number;
    const sizeQuantity = (yield select(selectSizeQuantity(action.productId, action.size))) as number;

    const product = (yield select(selectCartProduct(action.productId))) as IProduct;

    const availableQuantity = product && product.type === 'apparel' && !product.sizes?.isUniversal ? sizeQuantity : productQuantity;


    yield put(updateCartProductQuantity(action.productId, Math.min(availableQuantity, action.quantity), action.size));
}

export function* cartSagas(): Generator {
  yield takeLatest(LoadCart, loadCartSaga);
  yield takeLatest(ProcessCartProductQuantityUpdate, updateQuantitySaga);
}