import React, { useEffect } from 'react';

import Header from '../Header';
import { BrowserRouter, Route } from 'react-router-dom';
import AppRouter from './AppRouter';
import './style.scss';
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Footer from '../common/footer';
import SubscribeEmailModal from '../../subscribe/modal';
//@ts-ignore
import Banner from 'react-js-banner';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../store/state';
import { fetchConfiguration } from '../../store/global';
import { Link } from '@fluentui/react';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchConfiguration())
  }, []);

  
  let theme = createMuiTheme({
    palette: {
      primary: {
        main: "#2f9e9e",
      },
      secondary: {
        main: "#c7d0d4",
      },
    },
  });
  const global = useSelector((state: IRootState) => state.global);
  
  theme = responsiveFontSizes(theme);

  let banner = global.bannerMessage ? <div>
  <span>{global.bannerMessage}</span>
  </div> : null;
  if (global.bannerLink) {
    const messageParts = global.bannerMessage?.split('{link}') ?? [global.bannerMessage];
    banner = <div>
      <span>{messageParts[0]}<Link href={global.bannerLink.href}>{global.bannerLink.text}</Link>{messageParts[1]}
      {global.bannerEmoticon && <span role="img"> {global.bannerEmoticon}</span>}
      </span>
    </div>
  }

  return (
    <>
    {global.bannerMessage && <Banner showBanner={!!global.bannerMessage}>
      {banner}</Banner>}
      <ReactNotification />
      <SubscribeEmailModal />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
            <link href="https://fonts.googleapis.com/css?family=Dosis|Oswald|Montserrat&display=swap" rel="stylesheet"></link>
            <Route path="/" component={Header} />
            <main>
              <AppRouter/>
            </main>
            <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}


export default App;
