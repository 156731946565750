import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import './style.scss';
import ImageGallery from 'react-image-gallery';
import DimensionsComponent from '../../Dimension/dimension';
import 'react-tabs/style/react-tabs.css';
import { IRootState } from '../../../store/state';
import { BaseComponent } from '../../../utils/core/baseComponent';
import { IProduct, IProductType, SizeValues } from '../../../types';
import { shelfReducer } from '../store/reducer';
import { IShelfState } from '../store/state';
import { Action, compose } from 'redux';
import { injectReducer, injectSaga } from 'redux-injectors';
import { shelfSagas } from '../store/sagas';
import { addToCart }  from '../../../store/cart/actions';
import {fetchProducts, createSurfboard} from '../store/actions';
import { Link } from 'react-router-dom';
import { getAvailableSizeCount } from '../../../helpers/sizeQuantity';
import { mergeStyleSets } from '@fluentui/react';
import SubscribeField from '../../common/subscribe';
import { SizePicker } from '../../common/pickers/sizePicker';

export interface IProductDetailsState {
  selectedSize: SizeValues | undefined;
}

export interface IProductDetailsProps {
  product: IProduct;
  products: IProduct[];
  addToCart: (product: IProduct) => void;
  createSurfboard: (id: string) => void;
  fetchProducts: (id?: string, active?: boolean, type?: IProductType) => void;
}


const classes = mergeStyleSets({
  createButton: {
    color: '#ececec !important',
    backgroundColor: '#2f9e9e !important'
  },
  tabs: {
    paddingTop: '24px',
  },
  subscribe: {
    paddingTop: '12px',
  },
  detailsHeader: {
    paddingTop: '8px',
  },
  imageGallery: {
    width: '100%',
    margin: 'auto',
    // eslint-disable-next-line
    ['@media screen and (min-width: 880px)']: {
      width: '50% !important'
    }
  }
})

class ProductDetails extends BaseComponent<IProductDetailsProps, IProductDetailsState> {

  constructor(props: IProductDetailsProps) {
    super(props);
    this.state = {
      selectedSize: undefined,
    };
    this.props.fetchProducts(this.productId);
  }
  
  componentDidUpdate() {
    if (this.type === 'apparel' && !this.state.selectedSize) {
      const next = this.getNextAvailableSize();
      if (next) {
        this.setState({selectedSize: next});
      }
    }
    else if (this.state.selectedSize && this.getAdjustedAvailableSizeCount(this.state.selectedSize) < 1) {
      const nextSize = this.getNextAvailableSize();
      if (nextSize && nextSize !== this.state.selectedSize) this.setState({selectedSize: nextSize});
    }
  }
  
  get productId() {
    return (this.props as any).match.params.id;
  }

  get isSoldOut() {
    if (this.type !== 'apparel' || this.props.product.sizes?.isUniversal) {
      const IProduct = this.getCartProduct();
      return this.props.product.quantity <= (IProduct ? IProduct.quantity : 0);
    }

    return !this.getNextAvailableSize();
  }

  get dimensions() {
    return this.props.product.dimensions;
  }

  get length(): string {
    return this.dimensions ? `${Math.floor(this.dimensions.lengthInches / 12)}' ${this.dimensions.lengthInches % 12}"` : '';
  }

  get type(): IProductType {
    return this.props.product ? this.props.product.type : 'surfboard';
  }

  get sizes() {
    return this.props.product.sizes;
  }

  private getCartProduct(size?: SizeValues) {
    return this.props.products.find(e => e.id === this.props.product.id && e.size === size)
  }

  private getAdjustedAvailableSizeCount(size?: SizeValues) {
    const alreadyInCart = this.getCartProduct(size);
    const alreadyInCartCount = alreadyInCart ? alreadyInCart.quantity : 0;
    const available = size ? getAvailableSizeCount(this.props.product, size) || 0 : this.props.product.quantity;
    return available - alreadyInCartCount;
  }

  addToCart() {
    this.props.addToCart({
      ...this.props.product,
      quantity: 1,
      size: this.state.selectedSize,
    });
  }

  private getNextAvailableSize(): SizeValues | undefined {
    if (this.getAdjustedAvailableSizeCount('XS') > 0) return 'XS';

    if (this.getAdjustedAvailableSizeCount('S') > 0) return 'S';

    if (this.getAdjustedAvailableSizeCount('M') > 0) return 'M';

    if (this.getAdjustedAvailableSizeCount('L') > 0) return 'L';

    if (this.getAdjustedAvailableSizeCount('XL') > 0) return 'XL';

    if (this.getAdjustedAvailableSizeCount('XXL') > 0) return 'XXL';
    
    if (this.getAdjustedAvailableSizeCount('XXXL') > 0) return 'XXXL';
    
    return undefined;
  }

  private get description() {

    const lines = this.props.product.description.split('\n');
    const children: any[] = [];
    lines.forEach(line => {
      if (children.length > 0) children.push(<br></br>);
      children.push(line);
    });

    return <Typography className='description' color="textSecondary" gutterBottom>
                {children}
            </Typography>;
  }
  
  render() {

    if (!this.props.product) {
      return <div/>
    }

    const isSoldOut = {
      XS: this.getAdjustedAvailableSizeCount('XS') <= 0,
      S: this.getAdjustedAvailableSizeCount('S') <= 0,
      M: this.getAdjustedAvailableSizeCount('M') <= 0,
      L: this.getAdjustedAvailableSizeCount('L') <= 0,
      XL: this.getAdjustedAvailableSizeCount('XL') <= 0,
      XXL: this.getAdjustedAvailableSizeCount('XXL') <= 0,
      XXXL: this.getAdjustedAvailableSizeCount('XXXL') <= 0,
    };

    const images = this.props.product.images ? Object.keys(this.props.product.images).map(i => {
      return {original: this.props.product.images![i][0], thumbnail: this.props.product.images![i][0]}
    }) : undefined;

    const materialsSection = <div><h3>MATERIALS</h3>
    <Typography className='description' color="textSecondary" gutterBottom>
      {`Foam Type: ${this.props.product.materials?.foamType || 'PU'}`}
    </Typography>
    <Typography className='description' color="textSecondary" gutterBottom>
      {`Glass Type: ${this.props.product.materials?.glassType || 'Polyester'}`}
    </Typography></div>;


    return (

    
    <React.Fragment>
      <div className='details-container'>
      <ImageGallery additionalClass={classes.imageGallery} showBullets={true} showFullscreenButton={false} showPlayButton={false} items={images || []} />

        <div className='product-info'>

          <Typography className='title left-align' color="textPrimary" gutterBottom>
            {this.props.product.title}
          </Typography>
          {this.props.product.logoURL ? <img className='logo' src={this.props.product.logoURL} alt="Product Logo" /> : undefined}

          <Typography className='price left-align' color="textPrimary" gutterBottom>
            ${this.props.product.price}
          </Typography>
          
          { this.props.product.sizes && !this.props.product.sizes.isUniversal &&

            <SizePicker disabled={isSoldOut} selected={this.state.selectedSize} onChange={(selected) => this.setState({selectedSize: selected})}/>
          }
          <Button onClick={_ => this.addToCart()} disabled={this.isSoldOut} color="primary" variant="contained" className='page-button'>
            {this.isSoldOut ? "Sold Out" : "Add To Cart"}
          </Button>

          
          { this.props.product.type === 'surfboard' && <Button onClick={(_: any) => this.props.createSurfboard(this.props.product.id)} component={Link} to={`/Create`}  className={`page-button ${this.isSoldOut && classes.createButton}`}>
            Create Custom
          </Button>}

          {/*TODO: how to pass props to connected component */}
          { this.isSoldOut && <SubscribeField className={classes.subscribe}/> }


          { this.type === 'surfboard' && this.dimensions && <DimensionsComponent dimensions={[this.dimensions]}>
            </DimensionsComponent>}
            <h3 className={classes.detailsHeader}>DETAILS</h3>
            {this.description}
            {this.type === 'surfboard' && materialsSection}
        </div>

      </div>
    </React.Fragment>

    
    );
  }
}

const mapStateToProps = (state: IRootState, props: any) => {
  const { shelf: { products } } = state;
  return {
    product: products.find((p: any) => p.id === props.match.params.id),
    products: state.cart.products,
  };
}

export default compose<React.ComponentType>(
  injectReducer({
    key: 'shelf',
    reducer: shelfReducer as React.Reducer<IShelfState, Action>,
  }),
  injectSaga({ key: 'shelf', saga: shelfSagas }),
  connect(mapStateToProps, { addToCart, fetchProducts, createSurfboard }),
)(ProductDetails as any);
