import { Axios } from 'axios-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BlobStorageRequest } from '../types/azure-storage';
import { ApiBaseUri } from '../constants';

export class SasGeneratorService {
  getSasToken(): Observable<BlobStorageRequest> {
    return Axios.get<BlobStorageRequest>(
      `${ApiBaseUri}api/v1/blob/generatesastoken`
    ).pipe(map(res => res.data));
  }
}


