import { Dimensions } from "../../types/Dimensions";
import { CustomSurfboardOptions } from "../../types/CustomSurfboardOptions";
import { LogoInfo } from "../../types/LogoInfo";

  export enum CustomOrderActionTypes {
    UpdateField = 'Update_Field',
    ChangeStage = 'Change_Stage',
    SetStages = 'Set_Stage',
    ResetCreate = 'Reset_Create',
  }

  export interface CustomOrderData {
    [key: string]: string | Dimensions;
  }

  export interface CustomSurfboardSaveRequest {
    id: string;
    Shape: string;
    FinType: string;
    Dimensions: Dimensions;
    FoamType: string;
    GlassType: string;
    LogoInfo: LogoInfo;
  }

  export interface CustomSurfboardSaveResponse {
    id: string;
    name: string;
    price: number;
    sessionId: string;
  }
  
  export interface CustomSurfboardCreateState {
    options: CustomSurfboardOptions;
    stages: Stage[];
    activeStep: number;
    id: string;
  }

export interface Stage {
    header: string;
    id: string;
    fields: Field[];
}

export interface Field {
    caption: string;
    type: FieldType;
    id: string;
    isRequired: boolean;
    options?: string[];
    default?: string;
    outerColorField?: string;
    innerColorField?: string;
}

export enum FieldType {
    input,
    text,
    dimension,
    dropdown,
    header,
    imageUpload,
    colorPicker,
    colorModel,
    binaryImageSelection
}