import { Action } from "@reduxjs/toolkit";
import { IBlogList, IBlogPost } from "./state";

export const LoadBlog = 'LOAD_BLOG';
export const FetchBlogList = 'FETCH_BLOG_LIST';
export const FetchBlogListSuccess = 'FETCH_BLOG_LIST_SUCCESS';
export const FetchBlogListFailure = 'FETCH_BLOG_LIST_FAILURE';
export const FetchBlogPost = 'FETCH_BLOG_POST';
export const FetchBlogPostSuccess = 'FETCH_BLOG_POST_SUCCESS';
export const FetchBlogPostFailure = 'FETCH_BLOG_POST_FAILURE';


export interface IFetchBlogListAction extends Action<typeof FetchBlogList> {
    pageIndex: number;
    pageSize: number;
}
export interface IFetchBlogListSuccessAction extends Action<typeof FetchBlogListSuccess> {
    payload: IBlogList;
}
export interface IFetchBlogListFailureAction extends Action<typeof FetchBlogListFailure> {
    error: Error;
}

export interface IFetchBlogPostAction extends Action<typeof FetchBlogPost> {
    postId: string;
}
export interface IFetchBlogPostSuccessAction extends Action<typeof FetchBlogPostSuccess> {
    payload: IBlogPost;
}
export interface IFetchBlogPostFailureAction extends Action<typeof FetchBlogPostFailure> {
    error: Error;
}

export interface ILoadBlogAction extends Action<typeof LoadBlog> {
}



export type BlogActions = 
    ILoadBlogAction |
    IFetchBlogListAction |
    IFetchBlogListFailureAction |
    IFetchBlogListSuccessAction |
    IFetchBlogPostFailureAction |
    IFetchBlogPostAction |
    IFetchBlogPostSuccessAction;