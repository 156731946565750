import { put, takeLatest } from "redux-saga/effects";
import sanityClient from "../sanity-client";
import { fetchBlogList, fetchBlogListFailure, fetchBlogListSuccess } from "./actions";
import { FetchBlogList, IFetchBlogListAction, ILoadBlogAction, LoadBlog } from "./actionTypes";
import { IBlogList } from "./state";

export function* loadBlogSaga(
    _action: ILoadBlogAction,
): Generator {
   yield put(fetchBlogList(1));
}

export function* fetchBlogListSaga(
    action: IFetchBlogListAction,
): Generator {
    try {

        const response = (yield sanityClient
        .fetch(
          `*[_type == "post"]{
            title,
            slug,
            mainImage{
              asset->{
                _id,
                url
               }
             },
           body,
          "name": author->name,
          "authorImage": author->image
      }`)) as IBlogList;
        
        yield put(fetchBlogListSuccess(response))              
    } catch (error) {
        yield put(
            fetchBlogListFailure({
            name: 'fetchBlogListSaga Error',
            message: `${error.message}`,
          }),
        );
      }
}

// export function* fetchBlogPostSaga(
//     action: IFetchBlogPostAction,
// ): Generator {
//     try {

//         const response = (yield butter.post.retrieve(action.postId)) as IBlogPost;

//         yield put(fetchBlogPostSuccess(response));              
//     } catch (error) {
//         yield put(
//             fetchBlogPostFailure({
//             name: 'loadBlogPostSaga Error',
//             message: `${error.message}`,
//           }),
//         );
//       }
// }

export function* blogSagas(): Generator {
    yield takeLatest(LoadBlog, loadBlogSaga);
    yield takeLatest(FetchBlogList, fetchBlogListSaga);
    // yield takeLatest(FetchBlogPost, fetchBlogPostSaga);
}