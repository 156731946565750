import { Checkbox, FormControlLabel, makeStyles, NativeSelect, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { SubmitButton } from '../../common/button/submit';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { IShippingAddress } from '../store/types/address';
import {IValidationResults } from '../store/types';
import { stateOptions } from './constants';
import { store } from 'react-notifications-component';
import { ISubmitSubscribeAction, submitSubscribe } from '../../common/subscribe/store';

const useStyles = makeStyles({
    container: {
        position: 'fixed',
        top: 0,
        height: '100%',
        width: '100%',
        maxWidth: '560px',
        boxSizing: 'border-box',
        zIndex: 1001,
        transition: 'right 0.2s',
        backgroundColor: '#c7d0d4',
        overflowY: 'auto',
    },
    innerContainer: {
      padding: '48px 56px'
    },
    field: {
        display: 'inline-flex',
        ['@media (max-width:520px)']: { // eslint-disable-line no-useless-computed-key
          marginTop: '8px',
        }
    },
    innerField: {
      paddingRight: '10px',
      ['@media (max-width:520px)']: { // eslint-disable-line no-useless-computed-key
        paddingRight: '0px'
      }
    },
    selectField: {
      top: '16px',
      ['@media (max-width:520px)']: { // eslint-disable-line no-useless-computed-key
        top: '8px',
      }
    },
    fullWidth: {
      width: '100%'
    },
    halfWidth: {
      width: '50%',
      ['@media (max-width:520px)']: { // eslint-disable-line no-useless-computed-key
        width: '100%'
      }
    },
    thirdWidth: {
      width: '33%',
      ['@media (max-width:520px)']: { // eslint-disable-line no-useless-computed-key
        width: '100%'
      }
    },
    header: {
        fontSize: '18px',
        display: 'block',
    },
    innerHeader: {
      paddingTop: '20px',
    },
    backButton: {
      position: 'fixed',
      top: '16px',
      left: '40px',
      zIndex: 1002,
    }
  });
  interface ShippingRenderProps {
    className: string,
    validationResults: IValidationResults,
    address: IShippingAddress,
    onBackClick: () => void,
    onContinue: (address: IShippingAddress) => void,
    onSaveAddress: (address: IShippingAddress) => void,
    onSubscribeToMailingList: (action: ISubmitSubscribeAction) => void,
  }

const ShippingComponent: React.FC<ShippingRenderProps> = ({className, onBackClick, onContinue, onSaveAddress, address, onSubscribeToMailingList}) => {
    const classes = useStyles();
    const [email, setEmail] = useState<string>(address.email || '');
    const [firstName, setFirstName] = useState<string>(address.firstName || '');
    const [lastName, setLastName] = useState<string>(address.lastName || '');
    const [company, setCompany] = useState<string>(address.company || '');
    const [street1, setStreet1] = useState<string>(address.street1 || '');
    const [street2, setStreet2] = useState<string>(address.street2 || '');
    const [city, setCity] = useState<string>(address.city || '');
    const [country, setCountry] = useState<string>(address.country);
    const [state, setState] = useState<string>(address.state);
    const [zip, setZip] = useState<string>(address.zip || '');
    const [phone, setPhone] = useState<string>(address.phone || '');
    const [subscribeToMailingList, setSubscribeToMailingList] = useState<boolean>(true);

    const getAddress = (): IShippingAddress => ({
      email,
      firstName,
      lastName,
      company,
      street1,
      street2,
      city,
      country,
      state,
      zip,
      phone,
    });

    const onBlur = () => {
      onSaveAddress(getAddress());
    };

    const getMissingRequiredFields = () => {
      const missing = [];

      if (!email) missing.push('Email');
      if (!firstName) missing.push('First Name');
      if (!lastName) missing.push('Last Name');
      if (!street1) missing.push('Address');
      if (!city) missing.push('City');
      if (!country) missing.push('Country');
      if (!state) missing.push('State');
      if (!zip) missing.push('Zip');

      return missing;
    }

    const onSubmit = () => {

      const missing = getMissingRequiredFields();

      if (missing.length) {
        store.addNotification({
          title: 'Missing required fields:',
          message: missing.join(', '),
          type: 'warning',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          }
        });
        return;
      }

      const address = getAddress();

      onContinue(address)

      if (subscribeToMailingList) {
        onSubscribeToMailingList(submitSubscribe(address.email, address.firstName, address.lastName));
      }
    }

    return <>  
        <ArrowBackIosIcon className={classes.backButton} onClick={() => onBackClick()}></ArrowBackIosIcon>
        <div className={`${className} ${classes.container}`}>
          <div className={`${classes.innerContainer}`}>
          <span className={classes.header}>Contact Information</span>
          <TextField
          required
          id="shipping-email"
          label="Email"
          className={`${classes.field} ${classes.fullWidth}`}
          value={email}
          inputProps={{ "autoComplete": "shipping email"}}
          onChange={(event) => setEmail(event.target.value)}
          onBlur={onBlur}
        />
        <FormControlLabel
        control={
          <Checkbox
            checked={subscribeToMailingList}
            onChange={() => setSubscribeToMailingList(!subscribeToMailingList)}
            name="checkedB"
            color="primary"
          />
        }
        label="Subscribe to our Mailing list"
      />
        <span className={`${classes.header} ${classes.fullWidth} ${classes.innerHeader}`}>Shipping</span>
        <TextField
          required
          id="shipping-firstname"
          label="First Name"
          className={`${classes.field} ${classes.halfWidth} ${classes.innerField}`}
          value={firstName}
          inputProps={{ "autoComplete": "shipping given-name"}}
          onChange={(event) => setFirstName(event.target.value)}
          onBlur={onBlur}
        />
        <TextField
          required
          id="shipping-lastname"
          label="Last Name"
          className={`${classes.field} ${classes.halfWidth}`}
          value={lastName}
          inputProps={{ "autoComplete": "shipping family-name"}}
          onChange={(event) => setLastName(event.target.value)}
          onBlur={onBlur}
        />
        <TextField
          id="shipping-company"
          label="Company (optional)"
          className={`${classes.field} ${classes.fullWidth}`}
          value={company}
          onChange={(event) => setCompany(event.target.value)}
          onBlur={onBlur}
        />
        <TextField
          required
          id="shipping-address1"
          label="Address"
          className={`${classes.field} ${classes.fullWidth}`}
          value={street1}
          onChange={(event) => setStreet1(event.target.value)}
          onBlur={onBlur}
        />
        <TextField
          id="shipping-address2"
          label="Apartment, suite, etc. (optional)"
          className={`${classes.field} ${classes.fullWidth}`}
          value={street2}
          onChange={(event) => setStreet2(event.target.value)}
          onBlur={onBlur}
        />
        <TextField
          required
          id="shipping-city"
          label="City"
          className={`${classes.field} ${classes.fullWidth}`}
          value={city}
          onChange={(event) => setCity(event.target.value)}
          onBlur={onBlur}
        />
        <div className={`${classes.field} ${classes.thirdWidth} ${classes.selectField} ${classes.innerField}`}> 
        <NativeSelect
          required
          className={`${classes.selectField} ${classes.fullWidth}`}
          id="shipping-country"
          placeholder="Country"
          value={country}
          onChange={(event) => setCountry(event.target.value)}
          onBlur={onBlur}
        >
          <option value="US">United States</option>
          <option value="CA">Canada</option>
        </NativeSelect>
        </div>
        <div className={`${classes.field} ${classes.thirdWidth} ${classes.selectField} ${classes.innerField}`}> 
        <NativeSelect
          required
          className={`${classes.selectField} ${classes.fullWidth}`}
          placeholder="State"
          id="shipping-state"
          value={state}
          onChange={(event) => setState(event.target.value)}
          onBlur={onBlur}
        >
          {Object.keys(stateOptions).map(key => <option key={key} value={key}>{key}</option>)}
        </NativeSelect>
        </div>
        <TextField
          required
          id="shipping-zip"
          label="ZIP code"
          className={`${classes.field} ${classes.thirdWidth}`}
          value={zip}
          onChange={(event) => setZip(event.target.value)}
          onBlur={onBlur}
        />
        <TextField
          id="shipping-phone"
          label="Phone (optional)"
          className={`${classes.field} ${classes.fullWidth}`}
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
          onBlur={onBlur}
        />
        </div>
        <SubmitButton onClick={onSubmit} text='Continue'></SubmitButton>
      </div>
    </>;
}

export default ShippingComponent;
