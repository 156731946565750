import { Action } from "redux";
import { ActiveCheckoutPage } from "./state";
import { IShippingAddress } from "./types/address";
import { IGetShipmentResponse } from "./types/api";

export const SubmitShippingAddress = 'SUBMIT_SHIPPING_ADDRESS';
export const SubmitShippingAddressFailure = 'SUBMIT_SHIPPING_ADDRESS_FAILURE';
export const SubmitShippingAddressSuccess = 'SUBMIT_SHIPPING_ADDRESS_SUCCESS';
export const SubmitOrder = 'SUBMIT_ORDER';
export const SubmitOrderSuccess = 'SUBMIT_ORDER_FAILURE';
export const SubmitOrderFailure = 'SUBMIT_ORDER_SUCCESS';
export const SetSelectedShippingRate = 'SET_SELECTED_SHIPPING_RATE';
export const ChangeCheckoutPage = 'CHANGE_CHECKOUT_PAGE';
export const SetAddress = 'SET_ADDRESS';


export interface ISetAddressAction extends Action<typeof SetAddress> {
  payload: IShippingAddress,
}

export interface IChangeCheckoutPageAction extends Action<typeof ChangeCheckoutPage> {
  payload: ActiveCheckoutPage,
} 

export interface ISelectShippingRateAction extends Action<typeof SetSelectedShippingRate> {
  payload: string | undefined,
}

export interface ISubmitOrderAction extends Action<typeof SubmitOrder> {
}

export interface ISubmitOrderFailureAction
  extends Action<typeof SubmitOrderFailure> {
  payload: Error;
}

export interface ISubmitOrderSuccessAction
  extends Action<typeof SubmitOrderSuccess> {
  payload: IGetShipmentResponse;
}

export interface ISubmitShippingAddressAction extends Action<typeof SubmitShippingAddress> {
  payload: IShippingAddress,
}

export interface ISubmitShippingAddressFailureAction
  extends Action<typeof SubmitShippingAddressFailure> {
  payload: Error;
}

export interface ISubmitShippingAddressSuccessAction
  extends Action<typeof SubmitShippingAddressSuccess> {
  payload: IGetShipmentResponse;
}

export type CheckoutActions =
  | ISubmitShippingAddressAction
  | ISubmitShippingAddressSuccessAction
  | ISubmitShippingAddressFailureAction
  | ISubmitOrderFailureAction
  | ISubmitOrderSuccessAction
  | ISubmitOrderAction
  | ISelectShippingRateAction
  | IChangeCheckoutPageAction
  | ISetAddressAction;
