import React from 'react';
import { connect } from 'react-redux';

import ShelfHeader from './ShelfHeader';
import ProductList from './ProductList/ProductList';
import {fetchProducts } from './store/actions';

import './style.scss';
import { IRootState } from '../../store/state';
import { IProduct, IProductType } from '../../types';
import { BaseComponent } from '../../utils/core/baseComponent';
import { Action, compose } from 'redux';
import { injectReducer, injectSaga } from 'redux-injectors';
import { shelfReducer } from './store/reducer';
import { IShelfState } from './store/state';
import { shelfSagas } from './store/sagas';
import { BabelLoading } from 'react-loadingg';
import { loadingStyle } from '../../utils/commonStyles';

export interface IShelfProps {
  isLoading?: boolean;
  products: IProduct[];
  location: Location;
  fetchProducts: (id?: string, active?: boolean, type?: IProductType) => void;
}

class Shelf extends BaseComponent<IShelfProps> {

  private type: IProductType = 'surfboard';

  componentDidMount() {

    this.evaluateType();

    this.handleFetchProducts();
  }

  handleFetchProducts(
  ){
    this.setState({ isLoading: true });
    this.props.fetchProducts(undefined, true, this.type);
  };

  private evaluateType() {
    switch(this.props.location.pathname.toLocaleLowerCase()) {
      case '/surfboards': 
        this.type = 'surfboard';
      break;
      case '/apparel':
        this.type = 'apparel';
    }
  }

  private get products(): IProduct[] {
    if (this.type) {
      return this.props.products.filter(p => p.type === this.type);
    }

    return this.props.products;
  }

  render() {

    return (
      <React.Fragment>
        {this.props.isLoading && <BabelLoading style={loadingStyle} speed={undefined as any} color="" size="" />}
        <ShelfHeader productsLength={this.products.length} />
        <div className="shelf-container">
          <ProductList products={this.products}/>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  products: state.shelf.products,
  isLoading: state.shelf.isLoading,
});

export default compose<React.ComponentType>(
  injectReducer({
    key: 'shelf',
    reducer: shelfReducer as React.Reducer<IShelfState, Action>,
  }),
  injectSaga({ key: 'shelf', saga: shelfSagas }),
  connect(mapStateToProps, { fetchProducts }),
)(Shelf);