import { Dimensions } from "./Dimensions";
import { FieldType, Stage } from "../store/customOrder/types";
import shroomLogo from '../assets/images/logos/belmontshroom.png';
import belmontRectangle from '../assets/images/logos/belmontTreeInRectangle.png';
import { availableDimensions } from "./availableDimensions";


export function getSteps() {
  return ['Shape', 'Dimensions', 'Materials', 'Fins & Leash', 'Colors', 'Logos'];
}

export const Names = {
  Shapes: {
    ShredderMcGavin: 'Shredder McGavin ($650)',
    StokeMachine: 'Stoke Machine ($650)',
    PinHead: 'Pinhead ($700)',
    Log: 'Log ($800)'
  },
  FinConstruction: {
    GlassIn: 'Glassed-in (+$100)'
  },
  FinType: {
    singleFinWithSideBonzers: 'Single fin with side bonzers (+75$)'
  },
  Colors: {
    NoColor: 'No color (white)',
    SolidColor: 'Single color (+$60)',
    TopAndBottomColors: 'Top & bottom (+$80)'
  },
  Leash: {
    Plug: 'Plug',
    GlassIn: 'Glassed-in (+$25)'
  },
  FoamType: {
    PU: 'PU',
    EPS: 'EPS (+$60)',
  },
  GlassType: {
    Epoxy: 'Epoxy (+$35)',
    Polyester: 'Polyester',
  }
};
export const finConstructionTypeField = { 
  id: 'FinConstructionType',
  caption: 'Glassed-in or Swappable fins?', type: FieldType.dropdown, 
  options: [
      Names.FinConstruction.GlassIn,
      'Swappable'
  ],
  default: 'Swappable',
  isRequired: true };

export const finBoxColorField = { 
    id: 'FinBoxColor',
    caption: 'Fin box color', type: FieldType.dropdown, 
    options: [
        'White',
        'Black'
    ],
    default: 'White',
    isRequired: true };

export const leashFields = [
  {
    id: 'LeashSectionHeader',
    caption: 'Leash Options', type: FieldType.header, 
    isRequired: false
  },
  {
    id: 'LeashPlugType',
    caption: 'Leash type', type: FieldType.dropdown, 
    options: [
        Names.Leash.Plug,
        Names.Leash.GlassIn
    ],
    default: 'Plug',
    isRequired: true 
  }
]

export const leashPlugColorField = { 
  id: 'LeashPlugColor',
  caption: 'Leash plug color', type: FieldType.dropdown, 
  options: [
      'White',
      'Black'
  ],
  default: 'White',
  isRequired: true };

export function getStages(): Stage[] {
  return [
      { 
        header: "Select Shape", 
        id: 'shapestage',
        fields: [
          { 
          id: 'Shape',
          caption: 'Choose a Shape', type: FieldType.dropdown, 
          options: [
              Names.Shapes.ShredderMcGavin,
              Names.Shapes.StokeMachine,
              Names.Shapes.PinHead,
              Names.Shapes.Log
          ],
          isRequired: true }
      ] },
      { header: "Choose Dimensions", 
        id: 'dimensionstage',
        fields: [
          {
              id: 'Dimensions',
              caption: 'Available Options', 
              type: FieldType.dimension,
              isRequired: true
          }
      ] },
      { header: "Choose Materials", 
      
          id: 'materialstage', fields: [
          { 
          id: 'FoamType',
          caption: 'Select a Foam Type', type: FieldType.dropdown, 
          options: [
              Names.FoamType.PU,
              Names.FoamType.EPS
          ],
          default: Names.FoamType.PU,
          isRequired: true },
          { 
            id: 'GlassType',
            caption: 'Select a Glass Type', type: FieldType.dropdown, 
            options: [
                Names.GlassType.Polyester,
                Names.GlassType.Epoxy
            ],
            default: Names.GlassType.Polyester,
            isRequired: true }
      ] },
      { header: "Choose Fins & Leash", 
      id: 'finstage', fields: [
          { 
          id: 'FinType',
          caption: 'Select a Fin Type', type: FieldType.dropdown, 
          options: [
              'Futures',
              'FCS'
          ],
          default: 'Futures',
          isRequired: true }
      ] },
      { header: "Colors", 
            id: 'colorstage', fields: [
          { 
              id: 'ColorPattern',
              caption: 'Color Options', 
              type: FieldType.dropdown,
              isRequired: true, 
              options: [
                Names.Colors.NoColor,
                Names.Colors.SolidColor,
                Names.Colors.TopAndBottomColors
              ],
              default: Names.Colors.NoColor
          },
          { 
            id: 'SingleColorPicker',
            caption: 'Solid Color', 
            type: FieldType.colorPicker,
            isRequired: false
        }
      ] },
      { header: "Logos", 
            id: 'logostage', fields: [
          { 
              id: 'LogoName',
              caption: 'Configure Logos', 
              type: FieldType.imageUpload,
              isRequired: false, 
          },
          {
            id: 'BelmontLogoName',
            caption: 'All boards will come with a Belmont Surf Co logo on each side.',
            options: [shroomLogo, belmontRectangle],
            type: FieldType.binaryImageSelection,
            isRequired:true
          },
          { 
            id: 'EstimatedTimeInfo',
            caption: 'Custom boards will typically be delivered in around 60 days.', 
            type: FieldType.text,
            isRequired: false, 
          },
          { 
            id: 'SupportInfo',
            caption: 'As we build your new funcraft, our Chads will be in contact over email to make sure we create the craft of your dreams.<br>Have questions? Just send an email over to hello@belmontsurfco.com.', 
            type: FieldType.text,
            isRequired: false, 
          },
          { 
            id: 'Email',
            caption: 'Email', 
            type: FieldType.input,
            isRequired: true
          }
      ] }
  ]
}

export interface FinOptions {
  boxOptions: string[];
  enableGlassInOption?: boolean;
  glassInOptions?: string[];
}

export interface BoardInfo {
    dimensions: Dimensions[],
    shape3dKey: string,
    finOptions: FinOptions
  }
export const boardInfoMap: {[key: string]: BoardInfo} = {
    [Names.Shapes.ShredderMcGavin]: {
      dimensions:availableDimensions.shredder,
       finOptions:{
         boxOptions: [
           '5-fin'
         ]
       },
      shape3dKey: '8613'
    },
    [Names.Shapes.StokeMachine]: {
      dimensions: availableDimensions.stokeMachine,
    finOptions: {
      enableGlassInOption: true,
      glassInOptions: [
        'Keel twin fins'
      ],
      boxOptions: [
        'Twin fins',
        'Quad fins'
      ]
    },
    shape3dKey: '8614'
    },
    [Names.Shapes.PinHead]: {
      dimensions: availableDimensions.pinhead,
      finOptions: {
        boxOptions: [
          'Single fin',
          Names.FinType.singleFinWithSideBonzers,
          'Thruster'
        ]
      },
      shape3dKey: '8612' },
    [Names.Shapes.Log]: {
      dimensions: availableDimensions.log,
    finOptions: {
      boxOptions: [
        'Single fin',
        'Thruster'
      ]
    },
    shape3dKey: '8615'}
  };