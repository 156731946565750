import logo from '../../assets/images/logos/belmont-tree-circle.png';
import React from 'react';
import { mergeStyleSets } from '@fluentui/react';

const styles = mergeStyleSets({
    emailText: {
        textDecoration: 'underline',
    },
    text: {
        textAlign: 'left',
        display: 'block'
    },
    textContainer: {
        width: '60%',
        margin: 'auto',
        paddingTop: '24px',
    },
    contactLogo: {
        paddingTop: '96px',
        width: '200px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex'
    },
    headerText: {
        paddingTop: '48px',
        display: 'flex',
        justifyContent: 'center',
    }
});

export const Contact: React.FC = () => {
    return (
        <div className='contact-content'>
            <h1 className={styles.headerText}>Contact Us</h1>
          <div className={styles.textContainer}>
            <span className={styles.text}>For all online inquiries, including online returns and exchanges, please contact:</span>
            <span className={`${styles.text} ${styles.emailText}`}>hello@belmontsurfco.com</span>
          </div>
          <img className={styles.contactLogo} src={logo}  alt="Belmont Surf Co logo" />
      </div>
      );
}
