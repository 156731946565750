import { CreateSurfboard, FetchProduct, ICreateSurfboardAction, IFetchProductAction } from "./actionTypes";
import { put, call, takeLatest } from 'redux-saga/effects';
import { requestWrapper } from "../../../utils/requestHelper";
import { fetchProductsFailure, fetchProductsSuccess } from "./actions";
import { IProduct, Names } from "../../../types";
import { updateOrder } from "../../../store/customOrder/actions";



export function* createSurfboardSaga(action: ICreateSurfboardAction): Generator {

  let shape = Names.Shapes.Log;

  switch(action.payload.id) {
    case '00':
      shape = Names.Shapes.ShredderMcGavin;
      break;
    case '01': 
    case '05':
      shape = Names.Shapes.StokeMachine;
      break;
    case '02':
    case '03':
      shape = Names.Shapes.PinHead;
      break;
    case '04':
      shape = Names.Shapes.Log
      break;
  }

  yield put(updateOrder({id: 'Shape', newValue: shape, stageIndex: 0}));

}

export function* $fetchProducts(
  action: IFetchProductAction,
): Generator {
  try {

    const params: any = {};

    if (action.payload.active != null) params['active'] = action.payload.active;
    if (action.payload.type != null) params['type'] = action.payload.type;
    if (action.payload.id != null) params['id'] = action.payload.id;
   
    const responseData = (yield call(requestWrapper as any, `api/v1/products/`, {
      params,
    })) as { items: IProduct[]};

    let items = responseData.items;
    
    if (action.payload.type === 'surfboard') {
      items = responseData.items.sort((a, b) => {
        return b.quantity - a.quantity;
      });
    }

    yield put(
      fetchProductsSuccess(items),
  );
  } catch (error) {
    yield put(
        fetchProductsFailure({
        name: 'fetchProducts Error',
        message: `${error.message}`,
      }),
    );
  }
}

export function* shelfSagas(): Generator {
  yield takeLatest(FetchProduct, $fetchProducts);
  yield takeLatest(CreateSurfboard, createSurfboardSaga);
}