import React, { FunctionComponent } from 'react';
import { ReactiveImageComponent } from '../ReactiveImage/ReactiveImageComponent';


type ThumbProps = {
  alt?: string,
  title?: string,
  className?: string,
  src?: string,
  images?: string[],
};

const Thumb: FunctionComponent<ThumbProps> = (props: ThumbProps) => {
  const image = props.images ? 
    <ReactiveImageComponent images={props.images} alt={props.alt} title={props.title}/> : 
    props.src ? <img src={props.src} alt={props.alt} title={props.title} /> : '';


  return (
    <div className={props.className}>
      {image}
    </div>
  );
};



export default Thumb;
