import React from 'react';
import { BaseComponent } from '../../utils/core/baseComponent';
import { Action, compose } from '@reduxjs/toolkit';
import { injectReducer, injectSaga } from 'redux-injectors';
import { IBlogList, IBlogState } from './store/state';
import { blogReducer } from './store/reducer';
import { blogSagas } from './store/sagas';
import { fetchBlogPost, loadBlog } from './store/actions';
import { IRootState } from '../../store/state';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mergeStyleSets } from 'office-ui-fabric-react';
import { Helmet } from 'react-helmet';

export interface IBlogListProps {
    list?: IBlogList;
    loadBlog: () => void;
    fetchBlogPost: (postId: string) => void;
}

const classes = mergeStyleSets({
    root: {
        padding: '24px',
    },
    image: {
        // paddingTop: '12px',
        maxWidth: '100%',
        maxHeight: '60vh',
    },
    backButton: {
      cursor: 'pointer',
    },
    header: {
        alignItems: 'baseline',
        marginLeft: '8px',
    }
});

class BlogList extends BaseComponent<IBlogListProps, any> {


    componentDidMount () {
        this.props.loadBlog();
    }

    render () {

        const allPostsData = this.props.list;

        if (!allPostsData) return <div>Loading...</div>;

        return (
            <div className={`${classes.root} bg-green-100 min-h-screen p-12`}>
              <Helmet>
                <title>Belmont Surf Company Blog</title>
                <meta name="description" content="A blog for surfers written by surfers. We dive into surf culture, tell what is important to us and share our experiences."/>
                <meta name="ad copy" content="Absorb some wisdom and knowledge."/>
              </Helmet>

              <div className="container mx-auto">
                <h2 className="text-5xl flex justify-center cursive">BLOG</h2>
                {/* <h3 className="text-lg text-gray-600 flex justify-center mb-12">
                </h3> */}
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {allPostsData &&
                    allPostsData.map((post, index) => post.slug && (
                      <Link to={"/Blog/" + post.slug.current} key={post.slug.current}>
                        <span
                          className="block h-64 relative rounded shadow leading-snug bg-white
                              border-l-8 border-green-400"
                          key={index}
                        >
                          <img
                            className={`${classes.image} w-full h-full rounded-r object-cover absolute`}
                            src={post.mainImage.asset.url}
                            alt=""
                          />
                          <span
                            className="block relative h-full flex justify-end items-end pr
                              -4 pb-4"
                          >
                            <h4
                              className="text-gray-800 text-lg font-bold px-3 py-4 bg-red-700
                                text-red-100 bg-opacity-75 rounded"
                            >
                              {post.title}
                            </h4>
                          </span>
                        </span>
                      </Link>
                    ))}
                </div>
              </div>
            </div>
          );
        }
    }

const mapDispatchToProps = {
    loadBlog,
    fetchBlogPost,
  };

const mapStateToProps = (state: IRootState) => ({
    list: state.blog.list
});

export default compose<React.ComponentType>(
    injectReducer({
      key: 'blog',
      reducer: blogReducer as React.Reducer<IBlogState, Action>,
    }),
    injectSaga({ key: 'blog', saga: blogSagas }),
    connect(mapStateToProps, mapDispatchToProps),
  )(BlogList);