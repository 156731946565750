import { IProduct, IProductType } from '../../../types';
import * as types from './actionTypes';

export function fetchProducts(id: string | null = null, active: boolean | null = null, type: IProductType | null = null): types.IFetchProductAction {
    return {
      type: types.FetchProduct,
      payload: {
          id,
          active,
          type
      }
    };
  }
  
  export function fetchProductsFailure(error: Error): types.IFetchProductFailureAction {
    return {
      type: types.FetchProductFailure,
      payload: error,
    };
  }
  
  export function fetchProductsSuccess(response: IProduct[]): types.IFetchProductSuccessAction {
    return {
      type: types.FetchProductSuccess,
      payload: response,
    };
  }
  
  export function createSurfboard(id: string) {
    return {
      type: types.CreateSurfboard,
      payload: {
        id
      }
    };
  }