import { IRootState } from "../../../store/state";
import { IRate, IShippingAddress } from "./types";


export interface ICheckoutInfo{ rate?: IRate, products: any[], address: IShippingAddress };

export const getCheckoutInfo = (state: IRootState): ICheckoutInfo => {

    const rate = state.checkout.shippingRates ? state.checkout.shippingRates.find(r => r.objectId === state.checkout.selectedShippingRate) : undefined;

    return {
        rate: rate,
        products: state.cart.products,
        address: state.checkout.address,
    };
  };