
// eslint-disable-next-line
/// <reference path="../react-app-env.d.ts" />

import { configureStore, EnhancedStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { Middleware, CombinedState, AnyAction, Dispatch } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { initialRootState, IRootState } from './state';
import { routerMiddleware, RouterState } from 'connected-react-router';
import { History } from 'history';
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors';
import {createReducer} from './reducers';
import { ICartState } from './cart/state';
import { globalSagas, IGlobalState } from './global';
import { loadCart } from './cart';
import { cartSagas } from './cart/sagas';
import { subscribeSagas } from '../components/common/subscribe/sagas';
import { disableSubscribePopupLocalStorageKey } from '../subscribe/constants';
import { changeSubscribeModal } from '../subscribe/actions';

const getDefaultState = () => {
  return initialRootState;
}

export function configureAppStore(
  history?: History,
  initialState: IRootState = getDefaultState(),
): EnhancedStore<
  CombinedState<{ router: RouterState<History.PoorMansUnknown>,
    global: IGlobalState,
    cart: ICartState, }>,
  AnyAction,
  Middleware<{}, unknown, Dispatch<AnyAction>>[]
> {

  const win = window as any;

  const reduxSagaContext = { context: { } };
  const sagaMiddleware = createSagaMiddleware(reduxSagaContext);
  const { run: runSaga } = sagaMiddleware;

    /*
    * Create the store with two middlewares
    * 1. sagaMiddleware: Makes redux-sagas work
    * 2. routerMiddleware: Syncs the location/URL path to the state
    */
    const middlewares = [sagaMiddleware] as Middleware[];

  if (history) {
    middlewares.push(routerMiddleware(history));
  }
  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ];

  if (win?.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(win.__REDUX_DEVTOOLS_EXTENSION__());
  }
  
  const store = configureStore({
    preloadedState: initialState,
    reducer: createReducer(),
    middleware: [...getDefaultMiddleware(), ...middlewares],
    devTools: false,
    enhancers,
  });

  sagaMiddleware.run(cartSagas);
  sagaMiddleware.run(subscribeSagas);
  sagaMiddleware.run(globalSagas);
  
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      forceReducerReload(store);
    });
  }

  store.dispatch(loadCart());

  const showSubscribePopup = !window.localStorage.getItem(disableSubscribePopupLocalStorageKey);
  if (showSubscribePopup) {
    setTimeout(() => store.dispatch(changeSubscribeModal(true)), 2000);
  }

  store.subscribe(() => {
    const state = store.getState();
    const persist = {
      cart: state.cart
    };

    window.localStorage.setItem('state', JSON.stringify(persist));
  });

  return store;
};
