import { Reducer } from "redux";
import { ChangeCheckoutPage, CheckoutActions, SetAddress, SetSelectedShippingRate, SubmitOrder, SubmitShippingAddress, SubmitShippingAddressFailure, SubmitShippingAddressSuccess } from "./actionTypes";
import { initialCheckoutState, ICheckoutState } from "./state";

export const checkoutReducer: Reducer<ICheckoutState, CheckoutActions> = (
    state: ICheckoutState = initialCheckoutState,
    action: CheckoutActions,
  ): ICheckoutState => {
    switch (action.type) {
      case SubmitOrder:
      case SubmitShippingAddress:
        return {
          ...state,
          isLoading: true,
        }
      case SubmitShippingAddressSuccess: 
        return {
          ...state,
          shippingRates: action.payload.rates,
          shippingValidationResults: action.payload.validationResults,
          isLoading: false,          
        };
      case SubmitShippingAddressFailure:
        return {
          ...state,
          shippingRates: undefined,
          shippingValidationResults: undefined,
          error: action.payload,
          isLoading: false,
        }
      case SetSelectedShippingRate:
        return {
          ...state,
          selectedShippingRate: action.payload,
        }
      case ChangeCheckoutPage:
        return {
          ...state,
          activePage: action.payload,
        }
      case SetAddress:
        return {
          ...state,
          address: action.payload,
        }
      default:
        return state;
    }
  };
  