export interface IGlobalConfiguration {
    bannerLink?: {
        href: string;
        text: string;
    };
    bannerMessage?: string;
    bannerEmoticon?: string;
}

export interface IGlobalState extends IGlobalConfiguration {
    isMobile?: boolean;
    isAdmin?: boolean;
}

export const initialGlobalState: IGlobalState = {
    isMobile: false,
    isAdmin: false,
}