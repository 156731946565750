import { IColumn } from "@fluentui/react";

export type ColumnKeys = 'description'|'price';

export const ratesColumns: IColumn[] = [
    {
        key: 'description',
        name: '',
        isIconOnly: true,
        minWidth: 100,
        fieldName: 'servicelevel:durationTerms'
    },
    {
        key: 'price',
        name: '',
        minWidth: 100,
        fieldName: 'amount',
    },

]