import * as React from 'react';

/**
 * An abstract base class for components in ServicesHub. The class gives access to the
 * SHAppContext singleton through this.appContext, access to strings through
 * this.strings and implements a rendering error boundary with componentDidCatch.
 */
export abstract class BaseComponent<P = {}, S = {}> extends React.Component<P, S> {

}
