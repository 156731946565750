import { store } from 'react-notifications-component';
import { put, call, takeLatest } from 'redux-saga/effects';
import { requestWrapper } from "../../../utils/requestHelper";
import { ISubmitSubscribeAction, ISubmitSubscribeSuccessAction, SubmitSubscribe, submitSubscribeFailure, SubmitSubscribeSuccess, submitSubscribeSuccess } from "./store";

export function* $submitSubscribe(
  action: ISubmitSubscribeAction,
): Generator {
  try {
   
    yield call(requestWrapper as any, `api/v1/Subscribe/`, {
      params: {},
      body: {
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      },
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    });

    yield put(submitSubscribeSuccess())
  } catch (error) {
    yield put(
        submitSubscribeFailure({
        name: 'submitSubscribe Error',
        message: `${error.message}`,
      }),
    );
  }
}

export function* $submitSubscribeSuccess(
  _action: ISubmitSubscribeSuccessAction,
): Generator {
  yield store.addNotification({
    title: 'Success',
    message: 'Email has been added.',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 5000,
    }
  });
}

export function* subscribeSagas(): Generator {
  yield takeLatest(SubmitSubscribe, $submitSubscribe);
  yield takeLatest(SubmitSubscribeSuccess, $submitSubscribeSuccess);
}