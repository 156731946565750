import { AnyAction, CombinedState, combineReducers, Reducer } from 'redux';
import { InjectedReducersType } from '../utils/types/injector-typings';
import { connectRouter, RouterState } from 'connected-react-router';
import history from '../utils/history';
import { ICartState } from './cart/state';
import { IGlobalState } from './global';
import { cartReducer } from './cart/reducer';
import { globalReducer } from './global/reducer';
import { shelfReducer } from '../components/Shelf/store/reducer';
import { IShelfState } from '../components/Shelf/store/state';
import { ICheckoutState } from '../components/checkout/store/state';
import { checkoutReducer } from '../components/checkout/store/reducers';
import { CustomSurfboardCreateState } from './customOrder/types';
import { customOrderReducer } from './customOrder/reducer';
import { IBlogState } from '../components/Blog/store/state';
import { blogReducer } from '../components/Blog/store/reducer';
import { ISubscribeEmailState, subscribeEmailReducer } from '../subscribe';

export function createReducer(
  injectedReducers: InjectedReducersType = {},
): 
  Reducer<
    CombinedState<{
      global: IGlobalState,
      router: RouterState,
      cart: ICartState,
      shelf: IShelfState,
      checkout: ICheckoutState,
      customOrder: CustomSurfboardCreateState,
      subscribe: ISubscribeEmailState,
      blog: IBlogState,
    }>,
    AnyAction
  >
 {
  const rootReducer = combineReducers({
    ...injectedReducers,
    cart: cartReducer,
    global: globalReducer,
    shelf: shelfReducer,
    checkout: checkoutReducer,
    customOrder: customOrderReducer,
    blog: blogReducer,
    subscribe: subscribeEmailReducer,
    router: connectRouter(history) as Reducer<RouterState, AnyAction>,
  }) as Reducer<
  CombinedState<{
    global: IGlobalState;
    router: RouterState;
    cart: ICartState;
    shelf: IShelfState;
    checkout: ICheckoutState;
    customOrder: CustomSurfboardCreateState;
    subscribe: ISubscribeEmailState;
    blog: IBlogState;
  }>,
  AnyAction
>;

  return rootReducer;
}
