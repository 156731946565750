import { ICheckoutState, initialCheckoutState } from "../components/checkout/store/state";
import { ICartState, initialCartState } from "./cart/state";
import { CustomSurfboardCreateState } from "./customOrder/types";
import { IGlobalState, initialGlobalState } from "./global";
import { IShelfState } from "../components/Shelf/store/state";
import { getInitialCustomSurfboardCreateState } from "./customOrder/reducer";
import { initialShelfState } from "../components/Shelf/store/reducer";
import { IBlogState, initialBlogState } from "../components/Blog/store/state";
import { initialSubscribeState, ISubscribeEmailState } from "../subscribe/state";

export interface IRootState {
    checkout: ICheckoutState,
    customOrder: CustomSurfboardCreateState,
    global: IGlobalState,
    cart: ICartState,
    shelf: IShelfState,
    blog: IBlogState,
    subscribe: ISubscribeEmailState,
}

export const initialRootState: IRootState = {
    global: { ...initialGlobalState },
    cart: {
        ...initialCartState,
    },
    checkout: {
        ...initialCheckoutState,
    },
    customOrder: {
        ...getInitialCustomSurfboardCreateState(),
    },
    shelf: {
        ...initialShelfState,
    },
    blog: {
        ...initialBlogState,
    },
    subscribe: {
        ...initialSubscribeState,
    }
}