import { action } from 'typesafe-actions';
import { IProduct, SizeValues } from '../../types';
import { LoadCart, AddToCart, RemoveProduct } from './actionTypes';
import * as types from './actionTypes';
import { ICartState } from './state';

export function processCartProductQuantityUpdate(productId: string, quantity: number, size?: SizeValues): types.IProcessProductQuantityUpdateAction {
  return {
    type: types.ProcessCartProductQuantityUpdate,
    quantity,
    productId,
    size
  };
}

export function updateCartProductQuantity(productId: string, quantity: number, size?: SizeValues): types.IUpdateProductQuantityAction {
  return {
    type: types.UpdateCartProductQuantity,
    quantity,
    productId,
    size
  };
}

export const loadCart = (): types.ILoadCartAction => ({
  type: LoadCart
});

export const loadCartSuccess = (state: ICartState): types.ILoadCartSuccessAction => ({
  type: types.LoadCartSuccess,
  payload: state,
});

export const loadCartFailed = (error: Error): types.ILoadCartFailedAction => ({
  type: types.LoadCartFailed,
  payload: error,
});

export const removeProduct = (product: any) => ({
  type: RemoveProduct,
  payload: product
});

export function clearCart(orderId: string): types.IClearCart {
  return {
    type: types.ClearCart,
    payload: {orderId}
  }
}

export function setPendingOrderId(orderId: string): types.ISetPendingOrderAction {
  return {
    type: types.SetPendingOrder,
    payload: {orderId},
  }
}

export const addToCart = (payload: IProduct) => action(AddToCart, payload);

export const updateTotal = (cartProducts: any) => (dispatch: any) => {
  let productQuantity = cartProducts.reduce((sum: any, p: any) => {
    sum += p.quantity;
    return sum;
  }, 0);

  let totalPrice = cartProducts.reduce((sum: any, p: any) => {
    sum += p.price * p.quantity;
    return sum;
  }, 0);

  let cartTotal = {
    productQuantity,
    totalPrice,
    currencyId: 'USD',
    currencyFormat: '$'
  };

  dispatch({
    type: types.UpdateTotal,
    payload: cartTotal
  });
};