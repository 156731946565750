import { makeStyles } from "@material-ui/core";
import React from 'react';
import './marketingCard.scss';

const useStyles = makeStyles({
    captionImage: {
        cursor: 'pointer',
        width: '100%',
    },
    container: {
        minWidth: '30px',
        marginTop: '24px',
        position: 'relative',
        ['@media screen and (min-width: 40em)']: { // eslint-disable-line no-useless-computed-key
          width: '50% !important',
          flexBasis: '50% !important'
        }
    },
    caption: {
        color: 'black', //BelmontStyles.primaryColor,
        position: 'absolute',
        display: 'inline-block',
        left: '10px',
        top: '0px',
        fontSize: '36px',
        cursor: 'pointer',
    }
});

export interface IMarketingCardProps {
    image: string;
    caption: string;
    href: string;
}

export function MarketingCard(props: IMarketingCardProps) {
    const classes = useStyles();

      return <div className={`marketing-card-container ${classes.container}`}> 
      <a href={props.href}>
        <img className={classes.captionImage} alt="logo1" src={props.image} />
        <span className={classes.caption}>{props.caption}</span>
      </a>
    </div>;
}