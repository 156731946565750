import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Dimensions } from '../../types/Dimensions';
import { Radio } from '@material-ui/core';


const useStyles = makeStyles({
    root: {
        marginTop: '14px',
        marginBottom: '8px'
    },
    headerRow: {
        fontSize: '13px',
        marginLeft: 'unset !important',
        marginRight: 'unset !important'
    },
    contentRow: {
        marginLeft: 'unset !important',
        marginRight: 'unset !important',
        backgroundColor: '#e0e0e0',
        borderBottom: '1px solid #768082',
        cursor: 'pointer'
    },
    content: {
        lineHeight: '42px'
    },
    leftField: {
        marginRight: "24px !important"
    },
    label: {
        width: "86px",
        fontSize: "16px",
        fontWeight: 600,
        display: "inline-flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        height: "48px",
        paddingRight: "16px"
    },
    pickerColumn: {
        position: 'absolute'
    }
  });

  interface DimensionRenderProps {
    dimensions: Dimensions[],
    selectedIndex?: number,
    onChange?: (newValue: Dimensions) => void,
    picker?: boolean
  }
  

const DimensionsComponent: React.FC<DimensionRenderProps> = ({dimensions, selectedIndex, picker, onChange}) => {
    const classes = useStyles();
    const handleChange = ((index: number, checked: boolean) => {
        if (onChange) onChange(dimensions[index])
    });

    const pickerColumn = (index: number) => 
            <span className="">
            <Radio  
                color="primary"
                checked={selectedIndex === index}
                onChange={(_, c) => handleChange(index, c)}
                value="a"
                name="radio-button-demo"/>
            </span>




    let rows = [];
    for(const [i, d] of dimensions.entries()) {
        const rem =(d.lengthInches % 12);
        const length = `${Math.floor(d.lengthInches / 12)}' ${rem ? rem.toPrecision(2) + '"' : ''}`;

        rows.push(
    <div key={i} onClick={() => handleChange(i, selectedIndex !== i)} className={`row--flush text--center margin-bottom--xsml row ${classes.contentRow}`}>
        {picker ? pickerColumn(i) : null}
        <span className="col">
        <span className={classes.content}>{length}</span>
        </span>
        <span className="col">
            <span className={classes.content}>{d.widthInches}"</span>
        </span>
        <span className="col">
            <span className={classes.content}>{d.thicknessInches}"</span>
        </span>
        <span className="col">
            <span className={classes.content}>{d.liters}</span>
        </span>
    </div>)
    }

    return (
        <div className={classes.root}>
                <div className={`row--flush text--center margin-bottom--xsml row ${classes.headerRow}`}>
                    <div className="col">
                        <span className="tiny">Length</span>
                    </div>
                    <div className="col">
                        <span className="tiny">Width</span>
                    </div>
                    <div className="col">
                        <span className="tiny">Thick</span>
                    </div>
                    <div className="col">
                        <span className="tiny">Vol (L)</span>
                    </div>
                </div>
                {rows}
              </div>)
}



export default DimensionsComponent;
