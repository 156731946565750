import React from 'react';
import { BaseComponent } from '../../utils/core/baseComponent';
import { Action, compose } from '@reduxjs/toolkit';
import { injectReducer, injectSaga } from 'redux-injectors';
import { IBlogPost, IBlogState } from './store/state';
import { blogReducer } from './store/reducer';
import { blogSagas } from './store/sagas';
import { fetchBlogPost, loadBlog } from './store/actions';
import { IRootState } from '../../store/state';
import { connect } from 'react-redux';
import imageUrlBuilder from '@sanity/image-url';
import sanityClient from './sanity-client';
import { mergeStyleSets, Stack } from '@fluentui/react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import * as H from 'history';

const BlockContent = require('@sanity/block-content-to-react');

const builder = imageUrlBuilder(sanityClient);
function urlFor(source: string) {
  return builder.image(source);
}

const classes = mergeStyleSets({
    root: {
        padding: '24px'
    },
    image: {
        paddingTop: '8px',
        maxHeight: '40vh',
    },
    backButton: {
      cursor: 'pointer',
      '&:hover':{
          color: '#2f9e9e',
      }
    },
    header: {
        alignItems: 'baseline',
        marginLeft: '8px',
    },
    title: {
        paddingRight: '8px',
    }
});

export interface IBlogProps {
    post?: IBlogPost;
    loadBlog: () => void;
    fetchBlogPost: (postId: string) => void;
    history: H.History;
}
class Blog extends BaseComponent<IBlogProps, any> {

    componentDidMount () {
        this.props.loadBlog();
    }

    onBackClick() {
        this.props.history.goBack();
    }

    render () {
        const postData = this.props.post;

        if (!postData) return <div>Loading...</div>;

        return (
            <div className={classes.root}>
                <Stack className={classes.header} horizontal>
                    <ArrowBackIosIcon className={classes.backButton} onClick={() => this.onBackClick()}></ArrowBackIosIcon>
                    <h2 className={classes.image}>{postData.title}</h2>
                </Stack>
                <img className={classes.image} src={urlFor(postData.mainImage).width(750).url() || ''} alt="" />
                <div>
                    <BlockContent
                    blocks={postData.body}
                    projectId={sanityClient.clientConfig.projectId}
                    dataset={sanityClient.clientConfig.dataset}
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    loadBlog,
    fetchBlogPost,
  };

const mapStateToProps = (state: IRootState) => ({
    post: state.blog.post
});

export default compose<React.ComponentType>(
    injectReducer({
      key: 'blog',
      reducer: blogReducer as React.Reducer<IBlogState, Action>,
    }),
    injectSaga({ key: 'blog', saga: blogSagas }),
    connect(mapStateToProps, mapDispatchToProps),
  )(Blog);
