import React, { useContext, useState } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StageComponent from '../Stage/stage';
import { connect, useDispatch } from 'react-redux';
import { CustomSurfboardCreateState } from '../../store/customOrder/types';
import * as customOrderActions from '../../store/customOrder/actions';
import { CustomSurfboardService } from '../../services/CustomOrderService';
import { makeStyles, MobileStepper, Button } from '@material-ui/core';
import { UploadsViewStateContext } from '../../contexts/viewStateContext';
import { getSteps } from '../../types/customOrderProcess';
import { ValidateOptions } from '../../types/CustomSurfboardOptions';
import { addToCart } from '../../store/cart/actions';
import { customOrderReducer } from '../../store/customOrder/reducer';
import { Action, compose } from 'redux';
import { injectReducer } from 'redux-injectors';
import { Redirect } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { resetCreate } from '../../store/customOrder/actions';
import { Helmet } from 'react-helmet';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../utils/ai';

const useStyles = makeStyles({
    root: {},
    stepper: {
        
    },
    mobileStepper: {
        paddingBottom: '20px',
        height: '56px'
    },
    boardViewer: {
        borderStyle:'none', 
        overflow:'hidden', 
        display: 'block',
        margin: 'auto'
    }
});

interface PropsFromState {
    state: CustomSurfboardCreateState;
    isMobile: boolean;
  }
  
  // Combine both state + dispatch props - as well as any props we want to pass - in a union type.
  type AllProps = PropsFromState;

const Create: React.FC<AllProps> = ({state, isMobile}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const activeStep = state ? state.activeStep : 0;
    const [pictures, setPictures] = useState([] as File[]);
    const isLast = activeStep === (state.stages.length - 1);
    const [isSubmitted, setIsSubmitted] = useState(false);

      const context = useContext(UploadsViewStateContext);
  
    const submitOrder = (state: CustomSurfboardCreateState) => {
      var options = {} as any;
      Object.keys(state.options).forEach((key) => {
              options[key] = (state.options as any)[key].Value;
      });
        CustomSurfboardService.save({
            ...options,
            id: state.id,
        } as any).subscribe(resp => {
            dispatch(addToCart({ ...resp, type: 'customsurfboard', quantity: 1 } as any));
            setIsSubmitted(true);
            dispatch(resetCreate());

            store.addNotification({
                title: 'Added to cart.',
                message: 'Custom surfboard added to cart.',
                type: 'info',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                  duration: 5000,
                }
              });
        });
    }
   
    const uploadPictures = () => {
        if (pictures.length) {
          context.uploadItems(pictures,  state.id);
        }
      };
  
      const handleSubmit = (isPrevious = false) =>  {
        if (!isPrevious && !ValidateOptions(state.options, state.stages[activeStep])) return;
  
        if (isLast && !isPrevious) {
          uploadPictures();
          submitOrder(state);
        }
  
        dispatch(customOrderActions.stageChange({type: !isPrevious ? 'next' : 'previous'}));
      };

    const steps = getSteps();
    
    if (!state.stages || !state.stages.length) return <div></div>
    const stages = state.stages;

    if (isSubmitted) {
      dispatch(customOrderActions.resetCreate());

      return <Redirect to="/apparel"/>
    }

    
    const xSteps = steps.map((label) => (
        <Step key={label}>
            <StepLabel>{label}</StepLabel>
        </Step>
        ));
    const xStepper = isMobile ? '' :
        <Stepper activeStep={activeStep}>
                {xSteps}
            </Stepper>;

    const xMobileStepper = isMobile ? 
        <MobileStepper
            variant="progress"
            steps={stages.length}
            position="static"
            activeStep={activeStep}
            className={classes.mobileStepper}
            nextButton={
            <Button size="small"  onClick={() => handleSubmit(false)}>
                {isLast ? 'Add To Cart': 'Next'}
            </Button>
            }
            backButton={
            <Button size="small" onClick={() => handleSubmit(true)} disabled={activeStep === 0}>
                Back
            </Button>
            }
        /> : '';
    return (
        <div>
            <Helmet>
                <title>Create Custom Surfboard</title>
                <meta name="description" content="Create and design a custom board for yourself."/>
                <meta name="ad copy" content="Create and design your custom board in a matter of minutes. Select from different shapes, dimensions, fin setups, colors and more! Built to last and to impress!"/>
            </Helmet>
            {xStepper}
            <StageComponent stageIndex={activeStep} updatePictures={setPictures} handleSubmit={handleSubmit} isMobile={isMobile} isFirst={activeStep === 0} isLast={activeStep === (stages.length - 1)} options={state.options} stage={stages[activeStep]}></StageComponent>
            {xMobileStepper}
        </div>)
}


const mapStateToProps = (state: any) => ({
    state: state.customOrder,
    isMobile: false
});


export default withAITracking(reactPlugin,
compose<React.ComponentType>(
    injectReducer({
      key: 'customOrder',
      reducer: customOrderReducer as React.Reducer<CustomSurfboardCreateState, Action>,
    }),
    connect(mapStateToProps),) (Create), 'CreateComponent');
