import React from 'react';

import Thumb from '../../Thumb/Thumb';
import { formatPrice } from '../../../utils/priceFormat';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { mergeStyleSets } from '@fluentui/react';
import { IProduct, SizeValues } from '../../../types';
import { BaseComponent } from '../../../utils/core/baseComponent';

function NumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      isNumericString

    />
  );
}

const styles = mergeStyleSets({
  quantityField: {
    maxWidth: '32px'
  }
});

export interface ICartProductProps {
  product: IProduct,
  removeProduct: (product: IProduct) => void,
  updateProductQuantity: (productId: string, quantity: number, size?: SizeValues) => void,
}

class CartProduct extends BaseComponent<ICartProductProps> {

  state = {
    isMouseOver: false
  };

  handleMouseOver = () => {
    this.setState({ isMouseOver: true });
  };

  handleMouseOut = () => {
    this.setState({ isMouseOver: false });
  };

  private get size(): SizeValues | undefined {
    if (this.props.product.type !== 'apparel' || this.props.product.sizes?.isUniversal) return undefined;


    return this.props.product.size;
  }
  
  handleChange = (changeEvent: any) => {
    this.props.updateProductQuantity(this.props.product.id,  Number(changeEvent.target.value), this.size,)
  }

  render() {
    const { product, removeProduct } = this.props as { product: IProduct, removeProduct: any};

    const classes = ['shelf-item'];

    if (!!this.state.isMouseOver) {
      classes.push('shelf-item--mouseover');
    }
        
    let primaryImage = product.images ? Object.keys(product.images)[0] : null;

    const title = product.size ? `${product.name} - ${product.size}` : product.name;

    return (
      <div className={classes.join(' ')}>
        <div
          className="shelf-item__del"
          onMouseOver={() => this.handleMouseOver()}
          onMouseOut={() => this.handleMouseOut()}
          onClick={() => removeProduct(product)}
        />
      <Thumb
          className="shelf-item__thumb"
          alt={product.name}
          images={primaryImage && product.images ? product.images[primaryImage] : undefined}
        />
        <div className="shelf-item__details">
          <p className="title">{title}</p>
          <TextField
            className={styles.quantityField}
            label="Quantity"
            value={product.quantity}
            onChange={this.handleChange.bind(this)}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
      />
        </div>
        <div className="shelf-item__price">
          <p>{`$ ${formatPrice(product.price)}`}</p>
        </div>
      </div>
    );
  }
}

export default CartProduct;
