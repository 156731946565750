import { call } from 'redux-saga/effects';
import { ApiBaseUri } from '../constants';
import { updateUrlParameter } from './jsHelper';

export interface IRequestParams {
  [key: string]: string | number;
  locale: string;
}

export type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export interface IRequestOptions {
  method?: RequestMethod;
  body?: unknown;
  params: IRequestParams;
  headers?: HeadersInit;
  baseUrlOverride?: string;
}

export interface IResponseError {
  errorCode: number;
  errorMessage: string;
  basePath: string;
}

export function encodeURI(path: string, baseUrlOverride?: string): string {
  return `${baseUrlOverride || ApiBaseUri}${path}`;
}

export function formatUrl(path: string, requestParams: IRequestParams, baseUrlOverride?: string): string {
  if (path === null || path === undefined) {
    throw new Error('path is not defined.');
  }

  if (!requestParams) {
    throw new Error('request params is not defined.');
  }

  Object.keys(requestParams).forEach(key => {
    path = updateUrlParameter(path, key, requestParams[key]);
  });

  return encodeURI(path, baseUrlOverride);
}

export function errorWrapper(
  response: Response,
  basePath: string,
  responseText?: string,
): void {
  if (response.status < 200 || response.status > 299) {
    const responseError: IResponseError = {
      errorCode: response.status,
      errorMessage: responseText ? responseText : `${response.status} : ${response.statusText}`,
      basePath,
    };
    throw responseError;
  }
}

export function* requestWrapper<IResponse = {}>(
  basePath: string,
  requestOptions: IRequestOptions,
): Generator {
  if (!requestOptions) {
    throw new Error('requestOptions is not defined.');
  }

  const requestMethod: RequestMethod = requestOptions.method || 'GET';
  const fetchOptions: RequestInit = {
    method: requestMethod,
    headers: requestOptions.headers as HeadersInit,
  };

  if (requestOptions.body) {
    fetchOptions.body = JSON.stringify(requestOptions.body);
  }

  const formattedUrl = formatUrl(basePath, requestOptions.params, requestOptions.baseUrlOverride);
  const response = (yield call(fetch, formattedUrl, fetchOptions)) as Response;
  const responseText = (yield call([response, response.text])) as string;
  errorWrapper(response, basePath, responseText);

  if (responseText?.length) {
    return JSON.parse(responseText) as IResponse;
  }

  return {};
}

export type DeepReadonly<T> = T extends (infer R)[]
  ? IDeepReadonlyArray<R>
  : T extends Function
  ? T
  : T extends object
  ? DeepReadonlyObject<T>
  : T;

  type IDeepReadonlyArray<T> = ReadonlyArray<DeepReadonly<T>>;

type DeepReadonlyObject<T> = {
  readonly [P in keyof T]: DeepReadonly<T[P]>;
};
