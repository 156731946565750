import { ISubmitOrderAction, ISubmitShippingAddressAction, SubmitOrder, SubmitShippingAddress } from "./actionTypes";
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { requestWrapper } from "../../../utils/requestHelper";
import { changeCheckoutPage, submitOrderFailure, submitShippingAddressFailure, submitShippingAddressSuccess } from "./actions";
import { IGetShipmentResponse } from "./types/api";
import { getCheckoutInfo, ICheckoutInfo } from "./select";
import { CheckoutLineItem } from "../../../types/CheckoutLineItem";
import { StripeCheckoutService } from "../../../services/StripeCheckoutService";
import { store } from "react-notifications-component";
import { IProduct } from "../../../types";
import { setPendingOrderId } from "../../../store/cart/actions";


function mapProductsToLineItem(products: IProduct[]): CheckoutLineItem[] {
  return products.map((e: IProduct) => {
    const images = e.images ? Object.keys(e.images).map(key => {
      const imgArr = e.images![key];
      if (imgArr.length) return imgArr[0];
      return undefined;
    }).filter(item => item && typeof item === 'string') as string[] : [];
    return {
      customOrderId: e.type === 'customsurfboard' ? e.id: null,
      name: e.name, 
      price: e.price,
      quantity: e.quantity,
      productId: e.id,
      type: e.type,
      dimensions: e.dimensions,
      images:  images,
      size: e.size,
      };
  });
}

export function* $submitOrder(
  _action: ISubmitOrderAction,
): Generator {
  try {
    const checkoutInfo = (yield select(getCheckoutInfo)) as ICheckoutInfo;

    if (!checkoutInfo.rate) throw new Error('No rate selected!');

    const lineItems = mapProductsToLineItem(checkoutInfo.products);
    
    lineItems.push({
      name: 'Shipping',
      price: Number(checkoutInfo.rate?.amount),
      quantity: 1,
      productId: undefined,
      type: 'shipping',
      images: []
    });
    const responseData = (yield call(requestWrapper as any, `api/v1/Orders/`, {
      params: {},
      body: {
        items: lineItems,
        shipping: checkoutInfo.address,
        rateId: checkoutInfo.rate.objectId,
      },
      method: 'PUT',
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    })) as any;

    yield put(setPendingOrderId(responseData.id));

    StripeCheckoutService.redirectToCheckout(responseData.sessionId);

  } catch (error) {
    yield put(
        submitOrderFailure({
        name: 'submitOrder Error',
        message: `${error.message}`,
      }),
    );
  }
}

export function* submitShipping(
    action: ISubmitShippingAddressAction,
): Generator {
    try {
      const checkoutInfo = (yield select(getCheckoutInfo)) as ICheckoutInfo;

      const lineItems = mapProductsToLineItem(checkoutInfo.products);

        const responseData = (yield call(requestWrapper as any, `api/v1/shipping/shipment`, {
            params: {},
            body: {
              items: lineItems,
              firstName: action.payload.firstName,
              lastName: action.payload.lastName,
              company: action.payload.company || '',
              street1: action.payload.street1,
              street2: action.payload.street2 || '',
              city: action.payload.city,
              state: action.payload.state,
              zip: action.payload.zip,
              country: action.payload.country,
              email: action.payload.email,
              phone: action.payload.phone || '',
              locale: 'en-us',
            },
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
          })) as IGetShipmentResponse;

          if (!responseData.validationResults.isValid){ 
            store.addNotification({
              title: 'Invalid Address',
              message: 'Please verify address is correct.',
              type: 'warning',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 5000,
              }
            });
          }
          
          yield put(submitShippingAddressSuccess(responseData));

          if (responseData.validationResults.isValid && responseData.rates.length) {
            yield put(changeCheckoutPage('rateSelection'));
          }
      } catch (error) {

        yield put(
            submitShippingAddressFailure({
            name: 'shippingSagas Error',
            message: `${error}`,
          }),
        );
      }
}

export function* checkoutSagas(): Generator {
  yield takeLatest(SubmitShippingAddress, submitShipping);
  yield takeLatest(SubmitOrder, $submitOrder);
}