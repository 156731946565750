import * as types from './actionTypes';
import { ActiveCheckoutPage } from './state';
import { IShippingAddress } from './types/address';
import { IGetShipmentResponse } from './types/api';

export function setAddress(address: IShippingAddress): types.ISetAddressAction {
  return {
    type: types.SetAddress,
    payload: address,
  }
}

export function setSelectedShippingRate(rateId: string | undefined): types.ISelectShippingRateAction {
  return {
    type: types.SetSelectedShippingRate,
    payload: rateId,
  };
}

export function submitOrder(): types.ISubmitOrderAction {
  return {
    type: types.SubmitOrder,
  };
}

export function submitOrderFailure(error: Error): types.ISubmitOrderFailureAction {
  return {
    type: types.SubmitOrderFailure,
    payload: error,
  };
}

export function submitOrderSuccess(response: IGetShipmentResponse): types.ISubmitOrderSuccessAction {
  return {
    type: types.SubmitOrderSuccess,
    payload: response,
  };
}

export function submitShippingAddress(address: IShippingAddress): types.ISubmitShippingAddressAction {
    return {
      type: types.SubmitShippingAddress,
      payload: address,
    };
  }

  export function submitShippingAddressFailure(error: Error): types.ISubmitShippingAddressFailureAction {
    return {
      type: types.SubmitShippingAddressFailure,
      payload: error,
    };
  }

  export function submitShippingAddressSuccess(response: IGetShipmentResponse): types.ISubmitShippingAddressSuccessAction {
    return {
      type: types.SubmitShippingAddressSuccess,
      payload: response,
    };
  }

  export function changeCheckoutPage(activePage: ActiveCheckoutPage): types.IChangeCheckoutPageAction {
    return {
      type: types.ChangeCheckoutPage,
      payload: activePage,
    }
  }