import React from 'react';
import './style.scss';
import { Link, Redirect } from 'react-router-dom';
import icon from '../../../assets/icons/surfboard-icon.png'
import { BaseComponent } from '../../../utils/core/baseComponent';
import { staticCategoryPages } from '..';

export interface ICategoryItemProps {
  caption: string;
  dropdown?: boolean;
  isMobile?: boolean;
  onStaticItemClick: ()=> void;
}

export interface ICategoryItemState {
  shouldRedirect: boolean;
}


class CategoryItem extends BaseComponent<ICategoryItemProps, ICategoryItemState> {

  private captionRef: React.RefObject<HTMLSpanElement>;
  private _width: number = 0;

  constructor(props: ICategoryItemProps) {
    super(props);
    this.captionRef = React.createRef();
    this.state = {
      shouldRedirect: false
    };
  }
  
  componentDidUpdate() {
    if (this.state.shouldRedirect) {
      this.setState({shouldRedirect: false});
    }
  }

  getCaptionWidth() {
    return this.props.caption.length * 8.4;
  }

  getLeftStyle() {
    const width = this._width || (this.captionRef.current ? this.captionRef.current.clientWidth : this.getCaptionWidth());
    if (!this._width) this._width = width;
    return `${width / 2 - 41}px`;
  }

  navigate() {
    this.setState({shouldRedirect: true});
  }

  private onClick() {
    if(staticCategoryPages.includes(this.props.caption)) this.props.onStaticItemClick();
  }

  render() {
    var body;

    if (this.state.shouldRedirect) {
      return <Redirect to={"/" + this.props.caption}></Redirect>
    }

    if (this.props.dropdown) {
        body = <details><summary>{this.props.caption}</summary></details>
    } else if (this.props.isMobile) {
      body = <Link onClick={() => this.onClick()} to={"/" + this.props.caption}>{this.props.caption}</Link>;
    }
    else {
        body = 
        <React.Fragment>
          <span  onClick={() => this.navigate()} ref={this.captionRef}>{this.props.caption}</span>
          <div className='icon-container'>
            <img className='hover-icon' src={icon} alt="" style={{display:'none', left: this.getLeftStyle()}} />
          </div>
        </React.Fragment>
    }

    return (
    <li className='category-element'>
        {body}
    </li>
    );
  }
}

export default CategoryItem;
