import { getAvailableSizeCount } from "../../helpers/sizeQuantity";
import { IProduct, SizeValues } from "../../types";
import { IRootState } from "../state";

export const selectProductQuantity = (productId: string) => (state: IRootState): number | undefined => {


    const product = state.shelf.products.find(p => p.id === productId);

    if (!product) return 0;
    
    const productQuantity = product.type === 'customsurfboard' ? 10 : product.quantity;
    
    return productQuantity;
  };


  export const selectSizeQuantity = (productId: string, size?: SizeValues) => (state: IRootState): number | undefined => {


    const product = selectCartProduct(productId)(state)

    if (!product) return 0;
    
    const sizeQuantity = (getAvailableSizeCount(product, size) || 0);

    return sizeQuantity;
  };

  export const selectCartProduct = (productId: string) => (state: IRootState): IProduct | undefined => {


    const product = state.cart.products.find(p => p.id === productId);

    return product;
  };