import React, { Component } from "react";
import Slider from "react-slick";
import './style.scss';
import 'slick-carousel/slick/slick-theme.css'
import "slick-carousel/slick/slick.css";
import slide1 from '../../assets/images/WelcomeSlideshow/slide1.jpg';
import { MarketingCard } from "../common/pickers/marketingCard";
import { mergeStyleSets, Stack } from "@fluentui/react";

const classes = mergeStyleSets({
  container: {
    flexFlow: 'wrap',
  }
});

class WelcomeSlider extends Component {

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      adaptiveHeight: true,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <>
        <div className='slider-container'>
            <Slider {...settings}>
                <div>
                <img src={slide1} alt="Welcome" />
                </div>
            </Slider>

        </div>
        <Stack horizontal className={classes.container}>
          <MarketingCard href="/apparel" caption="SHOP CLOTHING" image="https://surfshop.blob.core.windows.net/marketing/clothes_card.jpg"/>
          <MarketingCard href="/surfboards" caption="SHOP SURFBOARDS" image="https://surfshop.blob.core.windows.net/marketing/board_card.jpg"/>
        </Stack>
      </>
    );
  }
}

export default WelcomeSlider;