import { Action } from "@reduxjs/toolkit";

export const SubmitSubscribe = 'SUBMIT_SUBSCRIBE';
export const SubmitSubscribeFailure = 'SUBMIT_SUBSCRIBE_FAILURE';
export const SubmitSubscribeSuccess = 'SUBMIT_SUBSCRIBE_SUCCESS';


export interface ISubmitSubscribeAction extends Action<typeof SubmitSubscribe> {
  payload: {
    email: string,
    firstName?: string,
    lastName?: string,
  };
};

export interface ISubmitSubscribeFailureAction extends Action<typeof SubmitSubscribeFailure> {
    payload: {
        error: Error
    };
};

export type ISubmitSubscribeSuccessAction = Action<typeof SubmitSubscribeSuccess>;

export function submitSubscribeFailure(error: Error): ISubmitSubscribeFailureAction {
    return {
        type: SubmitSubscribeFailure,
        payload: { error },
    }
}

export function submitSubscribeSuccess(): ISubmitSubscribeSuccessAction {
    return {
        type: SubmitSubscribeSuccess,
    }
}


export function submitSubscribe(email: string, firstName?: string, lastName?: string): ISubmitSubscribeAction {
    return {
      type: SubmitSubscribe,
      payload: { email, firstName, lastName },
    }
}