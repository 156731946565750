import { makeStyles } from "@material-ui/core";
import React from 'react';
import { SizeValues } from "../../../types";
import { Selectable } from "./selectable";

const useStyles = makeStyles({
      imagesContainer: {
        display: 'flex',
        flexWrap: 'wrap',
      }
});



export interface ISizePickerProps {
    selected: SizeValues | undefined;
    onChange: (selected: SizeValues) => void;
    disabled: {
        XS?: boolean;
        S?: boolean;
        M?: boolean;
        L?: boolean;
        XL?: boolean;
        XXL?: boolean;
        XXXL?: boolean;
    }
}

const defaultSizes: SizeValues[] = [
    'XS',
    'S',
    'M',
    'L',
    'XL',
    'XXL',
    'XXXL'
];

export function SizePicker(props: ISizePickerProps): JSX.Element {
    const classes = useStyles();

      const sizes = defaultSizes.map((value) => {
          return <Selectable disabled={props.disabled[value]} caption={value} value={value} selected={value === props.selected} onSelected={(value) => props.onChange(value as SizeValues)}/>
      })

      return <div className={classes.imagesContainer}>
          {sizes}
      </div>
}