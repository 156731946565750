import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { FormControl, NativeSelect, Button, TextField, InputLabel, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as customOrderActions from '../../store/customOrder/actions';
import { Stage, FieldType, Field, CustomOrderActionTypes } from '../../store/customOrder/types';
import ImageUploader from 'react-images-upload';
import DimensionsComponent from '../Dimension/dimension';
import ItemsUploaded from '../Storage/ItemsUploaded';
import './stage.scss';
import { CustomSurfboardOptions } from '../../types/CustomSurfboardOptions';
import { boardInfoMap } from '../../types/customOrderProcess';
import ColorPicker from './ColorPicker/colorpicker';
import { ImagePicker } from '../common/pickers/imagePicker';

const useStyles = makeStyles({
    root: {
      maxWidth: 400,
      flexGrow: 1,
    },
    container: {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        padding: "0 24px"
    },
    header: {
        fontSize: "24px",
        fontWeight: 600
    },
    formControl: {
        margin: "4px",
        minWidth: 120,
        marginBottom: "20px"
    },
      selectEmpty: {
        marginTop: "8px",
      },
    buttons: {
      display: 'flex'
    },
    footerButton: {
      width: "100%"
    },
    leftButton: {
      marginRight: '8px'
    },
    boardViewer: {
        borderStyle:'none', 
        overflow:'hidden', 
        width:'calc(100% - 20px)',
        height:'40vh',
        display: 'block',
        margin: 'auto'
    },
    headerField: {
      fontWeight: 600,
      textAlign: 'center',
      fontSize: '24px'
    },
    colorModelInner: {
      width: '237px',
      height: '85px',
      borderRadius: '112px / 43px',
      left: '11px',
      top: '6px',
      position: 'relative'
    },
    colorModelOuter: {
      width: '260px',
      height: '100px',
      margin: 'auto',
      borderRadius: '130px / 50px'
    },
    colorModelStringer: {
      background: 'burlywood',
      width: 'inherit',
      height: '2px',
      top: 'calc(50% + -1px)',
      position: 'relative',
      zIndex: 1
    }
  });

  interface StageRenderProps {
    stage: Stage,
    stageIndex: number,
    options: CustomSurfboardOptions,
    isLast: boolean,
    isFirst: boolean,
    isMobile: boolean,
    updatePictures: (pictures: File[]) => void,
    handleSubmit: (isPrevious?: boolean) => void
  }
  
  const getBoardInfo = (options:CustomSurfboardOptions) => boardInfoMap[options.Shape.Value || ''];

const StageComponent: React.FC<StageRenderProps> = ({stage, stageIndex, options, isLast, isFirst, isMobile,updatePictures, handleSubmit}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleChange = (change: customOrderActions.FieldUpdateEvent) => dispatch(customOrderActions.updateOrder(change));

    const onDrop = (files: File[], _pictures: string[]) => {           
      handleChange({ id: 'LogoName', newValue: files.length ? files[0].name : null, stageIndex})
      updatePictures(files);
    };


    const getFields = (field: Field) =>  { 
    return  <FormControl className={classes.formControl} key={field.id}>
              {getInput(field, stageIndex, classes, options, handleChange, onDrop)}
          </FormControl>
    }

    const boardData = getBoardInfo(options);
    const shape3dKey = boardData ? boardData.shape3dKey : '';
    const shape3dBoard = isFirst && shape3dKey ? <iframe title="shape-3d-iframe" className={classes.boardViewer} src={`https://www.shape3d.com/Viewers/Viewer3D.aspx?Board=${shape3dKey}`}></iframe> : '';

    const fields = stage.fields.map((f, i) => getFields(f));

    const buttons = isMobile ? '' :  <div className={classes.buttons}>
    {!isFirst ? <Button onClick={_ => handleSubmit(true)} variant="contained" color="secondary" className={`${classes.footerButton} ${classes.leftButton}`}>
      Back
    </Button> : null}
    <Button onClick={_ => handleSubmit()} variant="contained" color="primary" className={`${classes.footerButton}`}>
      {!isLast ? 'Next' : 'Add To Cart'}
    </Button>
  </div>;

    return (
        <div className={classes.container}>
            <span className={classes.header}>{stage.header}</span>
            {shape3dBoard}
            {fields}
            {buttons}
        </div>)
}

const getFieldValue = (options: any, key: string) => {
  const v = options[key];
  return v && v.Value;
}

const getInput = (field: Field, 
  stageIndex: number,
  classes: any,
  data: CustomSurfboardOptions, 
  handleChange: (change: customOrderActions.FieldUpdateEvent) => {
    type: CustomOrderActionTypes.UpdateField;
    payload: customOrderActions.FieldUpdateEvent; },
  onDrop: any) => {
  switch(field.type) {
    case FieldType.binaryImageSelection:
      const images = field.options!;
      let imageValue = getFieldValue(data, field.id);
      if (!imageValue) {

        handleChange({ id: field.id, newValue: images[0], stageIndex})
      }

      return <ImagePicker
      onChange={(selected: string) => handleChange({ id: field.id, newValue: selected, stageIndex})}
       caption={field.caption} 
       selected={imageValue} 
       images={images}/>
    case FieldType.dropdown:
        const options = field.options!;
        const optionsX = options!.map(o => <option key={o} value={o}>{o}</option>);
        let value = getFieldValue(data, field.id);

        const valueIsUnset = (!value || !options.find(e => e === value));
        
        if (options.length === 1 && valueIsUnset) {
          handleChange({ id: field.id, newValue: options[0], stageIndex})
        } else if (valueIsUnset && field.default) {
          handleChange({ id: field.id, newValue: field.default, stageIndex})
        }
        const labelId = `${field.id}-select-label`;
        return <> 
        <InputLabel htmlFor={field.id} id={labelId}>{field.caption}</InputLabel>
        <NativeSelect
          id={field.id}
          className={classes.selectEmpty}
          value={ value || ''}
          name={field.caption}
          onChange={(event) => handleChange({ id: field.id, newValue: event.target.value, stageIndex})}
        >
          <option aria-label="None" value="" />
          {optionsX}
        </NativeSelect> </>;
    case FieldType.input:
      return  <TextField
          id={field.id}
          label={field.caption}
          className={classes.selectEmpty}
          value={getFieldValue(data, field.id)}
          name={field.caption}
          onChange={(event) => handleChange({ id: field.id, newValue: event.target.value, stageIndex})}
          inputProps={{ 'aria-label': 'age' }}
        />;
    case FieldType.header: 
      return <Typography className={classes.headerField}>{field.caption}</Typography>;
    case FieldType.colorPicker:

      return <>
      <Typography className={classes.colorPickerLabel} color="textPrimary"> {field.caption}
    </Typography>
      <ColorPicker onChange={(update) => handleChange({ id: field.id, newValue: update, stageIndex })}/>
      </>;
    case FieldType.colorModel: 

        const innerValue = getFieldValue(data, field.innerColorField || '') || 'transparent';
        const outerValue = getFieldValue(data, field.outerColorField || '') || 'black';
        return <>
          <div style={{ background: outerValue }} className={classes.colorModelOuter}>
            <div className={classes.colorModelStringer}></div>
            <div style={{ background: innerValue }} className={classes.colorModelInner}></div>
          </div>
        </>

    case FieldType.imageUpload:
      return  <React.Fragment>
      <ImageUploader
        withIcon={true}
        withPreview={true}
        singleImage={true}
        buttonText='Choose logos'
        onChange={onDrop}
        imgExtension={['.jpg', '.gif', '.png', '.gif']}
        maxFileSize={5242880}
    /><ItemsUploaded /></React.Fragment>

    case FieldType.text:
        const segments = field.caption.split('<br>');
        let innerText: any[] = [];
        segments.forEach(s => {
          if (innerText.length) innerText.push(<br></br>);
          innerText.push(s);
        });

      return <span className={classes.imageSelectionCaption}>{innerText}</span>;
    case FieldType.dimension:

      const dimensions = getBoardInfo(data).dimensions;
      const valueUnset = !data.Dimensions.Value || !dimensions.find(e => e.lengthInches === data.Dimensions.Value!.lengthInches);

      if (dimensions.length >= 1 && valueUnset) {

        handleChange({ id: field.id, newValue: dimensions[0], stageIndex})
      }

      return <DimensionsComponent selectedIndex={dimensions.findIndex(e => e === data.Dimensions.Value)} onChange={(newValue) => handleChange({ id: field.id, newValue, stageIndex})} picker={true} dimensions={dimensions}></DimensionsComponent>
}
};


export default StageComponent;
