import { Stack } from "@fluentui/react";
import { makeStyles } from "@material-ui/core";
import React from 'react';
const { SocialIcon } = require('react-social-icons');


const useStyles = makeStyles({
    container: {
        marginTop: 'auto',
        backgroundColor: '#f5f5f5',
        padding: '1rem 0',
        textAlign: 'center'
    },
    centerStack: {
        width: '210px',
        margin: 'auto',
        textAlign: 'center',
        fontSize: '11px'
    },
    copyright: {
        marginTop: '16px !important',
    }
  });

export interface IFooterProps {
}

function Footer(_props: IFooterProps) {
    const classes = useStyles();

    return <div className={classes.container}>
        <Stack className={classes.centerStack}>
            <span><SocialIcon url="https://www.instagram.com/belmontsurfco/" target="_blank" rel="noopener noreferrer"/></span>
            <span className={classes.copyright} >© 2021 Belmont Surf Company LLC</span>
        </Stack>
        
    </div>
}

export default Footer;