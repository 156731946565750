import { IRate, IValidationResults, IShippingAddress } from "./types";


export type ActiveCheckoutPage = 'shipping' | 'rateSelection' | undefined;
export interface ICheckoutState {
    address: IShippingAddress;
    shippingValidationResults?: IValidationResults;
    shippingRates?: IRate[];
    isLoading?: boolean;
    error?: Error;
    selectedShippingRate?: string;
    activePage?: ActiveCheckoutPage;
}

export const initialCheckoutState: ICheckoutState = {
    address: {
        country: 'US',
        state: 'AL',
        email: '',
        firstName: '',
        lastName: '',
        street1: '',
        street2: '',
        city: '',
        zip: '',
    },

};