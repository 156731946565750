import * as types from './actionTypes';
import { IBlogList, IBlogPost } from './state';

export const loadBlog = (): types.ILoadBlogAction => ({
    type: types.LoadBlog
});

export const fetchBlogList = (pageIndex: number, pageSize: number = 10): types.IFetchBlogListAction => ({
    type: types.FetchBlogList,
    pageIndex,
    pageSize,
});

export const fetchBlogListSuccess = (payload: IBlogList): types.IFetchBlogListSuccessAction => ({
    type: types.FetchBlogListSuccess,
    payload
});

export const fetchBlogListFailure = (error: Error): types.IFetchBlogListFailureAction => ({
    type: types.FetchBlogListFailure,
    error
});

export const fetchBlogPost = (postId: string): types.IFetchBlogPostAction => ({
    type: types.FetchBlogPost,
    postId
});

export const fetchBlogPostSuccess = (payload: IBlogPost): types.IFetchBlogPostSuccessAction => ({
    type: types.FetchBlogPostSuccess,
    payload
});

export const fetchBlogPostFailure = (error: Error): types.IFetchBlogPostFailureAction => ({
    type: types.FetchBlogPostFailure,
    error
});