import { IProduct } from "../../types";

export const initialCartState: ICartState = {
    products: [],
    total: {
      productQuantity: 0,
      totalPrice: 0,
      currencyId: 'USD',
      currencyFormat: '$'
    }
  };

export interface ICartState {
    products: IProduct[],
    pendingOrderId?: string,
    total: ITotalState,
}

export interface ITotalState {
    productQuantity: number;
    totalPrice: number;
    currencyId: string;
    currencyFormat: string;
  }