import { Stage } from "../store/customOrder/types";
import { Dimensions } from "./Dimensions";

export const ValidateOptions = (options: any, stage?: Stage) => {

    if (!stage) return true;

    for (let field of stage.fields) {
        const optionKey = Object.keys(options).find(option => option === field.id) || '';
        const option = options[optionKey] as CustomOrderOption<any> | undefined;

        if (option && option.IsRequired && !option.Value) {
            return false;
        }
    }

    return true;
}

export interface CustomSurfboardOptions {
    Email: CustomOrderOption<string>;
    Shape: CustomOrderOption<string>;
    FinType: CustomOrderOption<string>;
    Dimensions: CustomOrderOption<Dimensions>;
    FoamType: CustomOrderOption<string>;
    GlassType: CustomOrderOption<string>;
    LogoName: CustomOrderOption<string>;
    LogoNotes: CustomOrderOption<string>;
    LeashPlugType: CustomOrderOption<string>;
}

export interface CustomOrderOption<T> {
    Value: T | null;
    IsRequired: boolean;
}