import { FocusZone, IconButton, mergeStyleSets, Modal, Stack } from "@fluentui/react";
import { Action, compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { injectReducer } from "redux-injectors";
import { changeSubscribeModal, disableSubscribePopupLocalStorageKey, ISubscribeEmailState, subscribeEmailReducer } from ".";
import { IRootState } from "../store/state";
import { SubscribeField } from '../components/common/subscribe';
import logo from '../assets/icons/main-logo.png';
import { submitSubscribe } from "../components/common/subscribe/store";

const styles = mergeStyleSets({
  contentContainer: {
    margin: '16px',
  },
  icon: {
    margin: 'auto',
    height: '40px',
  },
  closeButton: {
    position: 'absolute',
    height: '40px',
    top: 0,
    right: 0,
  }
});

export interface ISubscribeEmailModalProps extends ISubscribeEmailState{
  submitSubscribe: (email: string) => void;
  changeSubscribeModal: (display: boolean) => void;
}

export function SubscribeEmailModal(props: ISubscribeEmailModalProps): JSX.Element {
  const onDismiss = () => { 
    props.changeSubscribeModal(false);
    window.localStorage.setItem(disableSubscribePopupLocalStorageKey, 'true');

  };

  const onSubmitSubscribe = (email: string) => {
    props.submitSubscribe(email);
    onDismiss();
  }

    return <Modal
    isOpen={props.displayModal}
  >
    <Stack className={styles.contentContainer}>
      <div>
        <IconButton
            className={styles.closeButton}
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel="Close popup modal"
            onClick={onDismiss}
          />
      </div>
    <img className={styles.icon} src={logo} alt="Logo" />
    <FocusZone>
       <SubscribeField submitSubscribe={onSubmitSubscribe} />
       </FocusZone>
    </Stack>
  </Modal>
}

const mapStateToProps = (state: IRootState) => ({
  displayModal: state.subscribe.displayModal
});

const mapDispatchToProps = {
  submitSubscribe,
  changeSubscribeModal
};

export default compose<React.ComponentType>(
  injectReducer({
    key: 'subscribe',
    reducer: subscribeEmailReducer as React.Reducer<ISubscribeEmailState, Action>,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(SubscribeEmailModal);