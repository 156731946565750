import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Thumb from '../../../Thumb/Thumb';
import { formatPrice } from '../../../../utils/priceFormat';
import './style.scss';
import { RouteComponentProps, withRouter } from "react-router";
import { mergeStyleSets } from '@fluentui/react';
import { IProduct, IProductType } from '../../../../types';

interface ProductProps {
  product: IProduct,
  history: any,
}

const getStyles = (type: IProductType) => {
  const paddingTop = type === 'surfboard' ? '150%' : '100%';

  const shelfItemMediaOverride = type === 'surfboard' ?
  // eslint-disable-next-line
    {['@media screen and (min-width: 65em)']: {
        width: '25% !important',
        flexBasis: '24.75% !important'
      }} : {};
 
  return mergeStyleSets({
    anchor: {    
      paddingTop: paddingTop,
      backgroundImage: 'none',
      display: 'block'
    },
    thumbImage: {
      top: '0px',
      position: 'absolute',
      width: '100%',
    },
    hoverImage: {
      opacity: 0
    },
    productInfoContainer: {
      position: 'relative',
    },
    shelfItem: {
      width: '50%',
      flexBasis: '49.75%',
      // eslint-disable-next-line
      ['@media screen and (min-width: 40em)']: {
        width: '33.33333333% !important',
        flexBasis: '33.08333333% !important',
      },      
      ...shelfItemMediaOverride
    }
  });
}

type AllProps = RouteComponentProps & ProductProps;

class Product extends React.Component<AllProps, any> {

  state = {
    toProduct: undefined
  };

  private get typePath(): string {
    switch(this.type) {
      case 'apparel':
        return 'Apparel/';
      case 'surfboard':
        return 'Surfboards/';
      default:
        return 'Surfboards/';
    }
  }

  private get type(): IProductType {
    return this.props.product.type;
  }

  render() {
    if (this.state.toProduct) {
      return <Redirect to={`/${this.typePath}${this.props.product.id}`} />;
    }

    const styles = getStyles(this.type)

    let formattedPrice = formatPrice(this.props.product.price);
    const imageKeys = Object.keys(this.props.product.images);
    let primaryImage = this.props.product.primaryPhoto || imageKeys[0];
    let hoverImage = this.props.product.secondaryPhoto || imageKeys[imageKeys.length - 1];
    const soldOutCaptionClass = this.props.product.quantity > 0 ? 'sold-out-caption-hidden' : 'sold-out-caption';
    return (
      <a
        href={`/${this.typePath}${this.props.product.id}`}
        className={`shelf-item ${styles.shelfItem}`}
        data-sku={this.props.product.sku}
      >
        <div>
          <span className={`shelf-anchor ${styles.anchor}`}>
          <Thumb
            className={`shelf-item__thumb primary ${styles.thumbImage}`}
            src={this.props.product.images[primaryImage][0]}
            images={this.props.product.images[primaryImage]}
            alt={this.props.product.title}
          />
          <Thumb
            className={`shelf-item__thumb secondary ${styles.hoverImage} ${styles.thumbImage}`}
            src={this.props.product.images[hoverImage][0]}
            images={this.props.product.images[hoverImage]}
            alt={this.props.product.title}
          />
          </span>
        </div>
        <div className={styles.productInfoContainer}>
        <p className="shelf-item__title">{this.props.product.title}</p>
        <span className={soldOutCaptionClass}>SOLD OUT</span>
        <div className="shelf-item__price">
          <div className="val">
            {/* <small>{this.props.product.currencyFormat}</small> */}
            <b>{formattedPrice.substr(0, formattedPrice.length - 3)}</b>
            <span>{formattedPrice.substr(formattedPrice.length - 3, 3)}</span>
          </div>
        </div>
        </div>
      </a>
    );
  }
}

const ProductWithRouter = withRouter(Product);

export default connect(
  null,
  null
)(ProductWithRouter);
