import { IProduct } from '../../types';
import { CartActions, AddToCart, RemoveProduct, ClearCart, SetPendingOrder, UpdateCartProductQuantity, LoadCartSuccess } from './actionTypes';
import { ICartState, initialCartState, ITotalState } from './state';


export function cartReducer(state: ICartState = initialCartState, action: CartActions): ICartState {
  switch (action.type) {
    case LoadCartSuccess:
      {
        return {
          ...state,
          ...action.payload,
        }
      }
    case AddToCart:
      {

      const productIndex = state.products.findIndex(e => e.id === action.payload.id && e.size === action.payload.size);
      if (productIndex >= 0) {
        const result = {...state.products[productIndex]};
        result.quantity += 1;
        const products = [...state.products];
        products[productIndex] = result;
        return {
          ...state,
          products: products,
          total: getTotal(products),
        };
      }

      const prods = [ ...state.products, action.payload];

      return {
        ...state,
        products: prods,
        total: getTotal(prods),
      };
    }
    case RemoveProduct:
      {
      const products = state.products.filter(e => (e.id !== action.payload.id) || (action.payload.size && action.payload.size !== e.size));
      return {
        ...state,
        products: products,
        total: getTotal(products),
      };
    }
    case ClearCart:
      if (action.payload.orderId === state.pendingOrderId) {
        return {
          ...state,
          products: [],
          total: initialCartState.total,
        }
      }
      return state;
    case SetPendingOrder:
      return {
        ...state,
        pendingOrderId: action.payload.orderId,
      }
    case UpdateCartProductQuantity:
    {
    const products = [
      ...state.products.map(product => {

        if (product.id === action.productId && (product.size === action.size || !action.size)) {
          return {
            ...product,
            quantity: action.quantity,
          }
        }
        return product;
      })
    ];
      return {
        ...state,
        products,
        total: getTotal(products),
      }
    }
    default:
      return state;
  }
}


function getTotal(cartProducts: IProduct[]): ITotalState {
  let productQuantity = cartProducts.reduce((sum: any, p: any) => {
    sum += p.quantity;
    return sum;
  }, 0);

  let totalPrice = cartProducts.reduce((sum: any, p: any) => {
    sum += p.price * p.quantity;
    return sum;
  }, 0);

  return {
    productQuantity,
    totalPrice,
    currencyId: 'USD',
    currencyFormat: '$'
  };
}