import { makeStyles } from "@material-ui/core";
import React from 'react';

const useStyles = makeStyles({
    button: {
      color: '#ececec',
      textTransform: 'uppercase',
      backgroundColor: '#2f9e9e',
      textAlign: 'center',
      padding: '15px 0',
      marginTop: '40px',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: '#000'
      },
      ['@media (max-width:520px)']: { // eslint-disable-line no-useless-computed-key
        top: '8px',
        position: 'sticky',
        bottom: 0
      }
    }
  });

export interface IButtonProps {
    onClick: () => void;
    text: string;
}

export function SubmitButton(props: IButtonProps) {
    const classes = useStyles();

    return <div onClick={() => props.onClick()} className={classes.button}>{props.text}</div>
}