import { action } from 'typesafe-actions';
import { CustomOrderActionTypes, Stage } from './types';

export interface FieldUpdateEvent {
    id: string;
    stageIndex: number;
    newValue: any;
  }
export interface StageChangeEvent {
  type: 'next' | 'previous';
};

export const resetCreate = () => action(CustomOrderActionTypes.ResetCreate);
  
export const updateOrder = (payload: FieldUpdateEvent) => action(CustomOrderActionTypes.UpdateField, payload);

export const stageChange = (payload: StageChangeEvent) => action(CustomOrderActionTypes.ChangeStage, payload);

export const setStages = (payload: Stage[]) => action(CustomOrderActionTypes.SetStages, payload);