import { Axios } from 'axios-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiBaseUri } from '../constants';
import { CustomSurfboardSaveRequest, CustomSurfboardSaveResponse } from '../store/customOrder/types';

export class CustomSurfboardService {

  static save(order: CustomSurfboardSaveRequest): Observable<CustomSurfboardSaveResponse> {  
    return Axios.put<CustomSurfboardSaveResponse>(
      `${ApiBaseUri}api/v1/Customs/`, order).pipe(map(res => res.data));
  }
}


