import { ChangeSubscribeModal, ISubscribeEmailActions } from "./actions";
import { initialSubscribeState, ISubscribeEmailState } from "./state";

export function subscribeEmailReducer(state: ISubscribeEmailState = initialSubscribeState, action: ISubscribeEmailActions): ISubscribeEmailState {
    switch(action.type) {
        case ChangeSubscribeModal: {
            return {
                ...state,
                displayModal: action.display
            }
        }
        default: return state;
    }
}