import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
    container: {
        '&:hover': {
            backgroundColor: '#c7d0d4'
          },
          '&::before': {
            content: '""',
            display: 'inline-block',
            verticalAlign: 'middle',
            height: '100%'
          },
          cursor: 'pointer',
          height: '40px',
          width: '40px',
          display: 'block',
          verticalAlign: 'middle',
          border: '1px solid #ddd',
          borderRadius: '4px',
          margin: '4px',
          textAlign: 'center',
    },
    selected: {
        borderColor: 'black !important',
        borderWidth: '2px !important'
    },
    disabled: {
        border: 'unset',
        backgroundColor: '#f2f0eb',
        // borderRadius: 'unset',
        cursor: 'unset',
        '&:hover': {
            backgroundColor: '#f2f0eb'
          }
    }
});

export interface ISelectableProps {
    caption: string;
    value: string;
    selected: boolean;
    onSelected: (value: string) => void;
    disabled?: boolean;
}

export function Selectable(props: ISelectableProps): JSX.Element {
    const classes = useStyles();

    const containerClasses = [
        classes.container
    ];
    if (props.disabled) containerClasses.push(classes.disabled)
    else if (props.selected) containerClasses.push(classes.selected);

    return  <div onClick={() => !props.disabled && props.onSelected(props.value)} className={containerClasses.join(' ')}>
        <span>{props.caption}</span>
    </div>;
}